import { UPDATE_BUILDING_PICTURE } from '@/Actions/building.js';
import { FREEZE_ROUTING, RESET_DATA, UNFREEZE_ROUTING, UPDATE_USER_PREFERENCES } from '@/Actions/index.js';
import { intlReducer } from 'react-intl-redux';
import { combineReducers } from 'redux';
import { buildingReducer } from './building.js';
import freezeRoutingReducer from './freezeRouting.js';
import { loadingReducer } from './loading.js';
import { menuReducer } from './menu.js';
import notificationReducer from './notification.js';
import { previousStateReducer } from './previous_state.js';
import realEstateReducer from './realEstate.js';
import { reportsReducer } from './reports.js';
import { scenarioReducer } from './scenario.js';
import { titleReducer } from './title.js';
import { userReducer } from './user.js';

const appReducer = combineReducers({
  building: buildingReducer,
  loading: loadingReducer,
  intl: intlReducer,
  notification: notificationReducer,
  reports: reportsReducer,
  realEstates: realEstateReducer,
  scenario: scenarioReducer,
  selectedMenu: menuReducer,
  title: titleReducer,
  user: userReducer,
  previous_state: previousStateReducer,
  freezeRouting: freezeRoutingReducer
});

const rootReducer = (state, action) => {
  let newState = { ...state };
  if (action.type == RESET_DATA) newState = { intl: state.intl };
  if (action.type == UPDATE_USER_PREFERENCES)
    newState = {
      ...state,
      user: { ...state.user, preferences: { ...state.user.preferences, ...action.payload.userPreferences } },
    };
  if (action.type == UPDATE_BUILDING_PICTURE)
    newState = {
      ...state,
      building: { ...state.building, picture: action.payload.picture },
      realEstates: {
        ...state.realEstates,
        list: state.realEstates.list.map(re => {
          if (re.id == state.realEstates.selected)
            re.buildings = re.buildings.map(building => {
              if (building.id == state.building.id) building.picture = action.payload.picture;
              return building;
            });
          return re;
        }),
      },
    };

  newState.previous_state = {
    intl: newState.intl,
    loading: newState.loading,
  };
  return appReducer(newState, action);
};

export default rootReducer;
