export const isRequired = fieldName => 'error.not_blank';

export const mustMatch = otherFieldName => {
  return fieldName => 'error.no_match';
};

export const minLength = length => {
  return fieldName => 'error.text.min_message';
};

export const maxLength = length => {
  return fieldName => 'error.text.max_message';
};

export const minValue = limit => {
  return fieldName => 'error.number.min_message';
};

export const maxValue = limit => {
  return fieldName => 'error.number.max_message';
};

export const isNumeric = fieldName => 'error.invalid.numeric';
export const isText = fieldName => 'error.invalid.text';
export const isEmail = fieldName => 'error.invalid.email';
export const isDate = fieldName => 'error.invalid.date';
