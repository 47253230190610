export const RESET_DATA = 'RESET_DATA';
export const UPDATE_USER_PREFERENCES = 'UPDATE_USER_PREFERENCES';


export const UpdateUserPreferences = (userPreferences = null) => {
  return {
    type: UPDATE_USER_PREFERENCES,
    payload: { userPreferences },
  };
};
export const resetData = () => {
  return { type: RESET_DATA };
};
