import { setUser } from '@/Actions/user';
import { baseUrl } from '@/Functions/index.js';
import LoginFormStyles from '@/Themes/loginForm';
import { validateUserInput } from '@/Validation/UserValidation';
import { Box, Button, Grid, IconButton, InputAdornment, Tab, Tabs, TextField, withStyles } from '@material-ui/core';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import React, { useEffect, useRef, useState } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { updateIntl } from 'react-intl-redux';
import { useDispatch } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
const formTabs = {
  login: 0,
  signUp: 1,
};
const LoginForm = ({ intl, classes, showSnackBar, showLoading, history, isLoading, ...props }) => {
  const [abortController, setAbortController] = useState(new AbortController());
  const dispatch = useDispatch();
  const [forgotPassword, setForgotPassword] = useState(false);
  const [errors, setErrors] = useState({});
  const [tabValue, setTabValue] = useState(0);
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const formRef = useRef();
  const [showPassword, setShowPassword] = useState(false);
  useEffect(() => {
    return () => {
      abortController.abort();
      setAbortController(new AbortController());
    };
  }, []);
  useEffect(() => {
    if (tabValue == formTabs.signUp) setIsButtonDisabled(true);
  }, [tabValue]);
  useEffect(() => {
    if (Object.values(errors).length == 0) {
      if (isButtonDisabled) setIsButtonDisabled(false);
    } else {
      if (!isButtonDisabled) setIsButtonDisabled(true);
    }
  }, [errors]);
  const handleTabChange = (e, value) => {
    setTabValue(value);
  };
  const showError = fieldName => Boolean(errors?.[fieldName]);
  const errorFor = fieldName => showError(fieldName) && <FormattedMessage id={errors?.[fieldName]} />;
  const clearErrorsOnFocus = (e = null) => {
    if (e == null) return setErrors({});
    e.preventDefault();
    let name = e.currentTarget.name;
    let currentErrors = { ...errors };
    delete currentErrors[name];
    setErrors(currentErrors);
  };
  const handleClickShowPassword = e => {
    setShowPassword(() => !showPassword);
  };
  const checkForError = e => {
    e.preventDefault();
    let { name, value } = e.target;

    let validation = validateUserInput(name, value);
    if (Object.keys(validation).length != 0) {
      setErrors({ ...errors, ...validation });
    } else {
      setErrors(prevErrors => {
        let _prevErrors = _.cloneDeep(prevErrors);
        delete _prevErrors[name];
        return _prevErrors;
      });
    }
  };
  useEffect(() => {
    clearErrorsOnFocus();
  }, [forgotPassword]);
  const handleSubmit = e => {
    showLoading(true);
    let form = e.target;
    let {
      username: usernameInput,
      password: passwordInput,
      email: emailInput,
      confirm_password: confirm_passwordInput,
    } = form.elements;
    let [_username, _password, _email, _confirm_password] = [
      usernameInput?.value ?? undefined,
      passwordInput?.value ?? undefined,
      emailInput?.value ?? undefined,
      confirm_passwordInput?.value ?? undefined,
    ];

    switch (tabValue) {
      case formTabs.login:
        if (forgotPassword) {
          props.setLoadingMessage(null);
          handleForgotPassword(_email);
          break;
        }
        props.setLoadingMessage('app.wait_for_login');
        handleLogin({ _username, _password });
        break;
      case formTabs.signUp:
        props.setLoadingMessage(null);
        handleSignUp({ _username, _password, _email, _confirm_password });
        break;
      default:
        break;
    }
    e.preventDefault();
  };
  const onLogin = user => {
    showSnackBar('login.success', 'success');
    showLoading(false);
    dispatch(setUser(user));

    if (user?.preferences?.language != intl.locale) {
      const language = user.preferences.language;
      dispatch(updateIntl({ locale: language, messages: require(`@/Translations/${language}.json`) }));
    }
    history.push('/real_estates');
  };
  const handleLogin = ({ _username, _password }) => {
    fetch(`${baseUrl}/login`, {
      method: 'POST',
      headers: {
        Accept: 'json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        username: _username,
        password: _password,
      }),
    })
      .then(response => response.json())
      .then(response => {
        showLoading(false);
        if (response.user) {
          onLogin(response.user);
        } else if (response.error) {
          setErrors({ username: response.error, password: response.error });
        } else {
          showSnackBar('app.response.unknown', 'error');
        }
      })
      .catch(error => {
        console.error(error);
      });
  };

  const handleForgotPassword = _email => {
    fetch(`${baseUrl}/forgot_password`, {
      method: 'POST',
      headers: {
        Accept: 'json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        email: _email,
      }),
    })
      .then(response => response.json())
      .then(response => {
        if (response.success) {
          showSnackBar(response.success, 'success');
          setForgotPassword(false);
        } else if (response.error) {
          if (response.email) {
            setErrors({ email: response.error });
          } else {
            showSnackBar(response.error, 'error');
          }
        } else {
          showSnackBar('app.response.unknown', 'error');
        }
        showLoading(false);
      })
      .catch(error => {
        console.error(error);
      });
  };

  const handleSignUp = ({ _username, _email, _confirm_password, _password }) => {
    let _errors = {};
    if (_username === '') _errors.username = 'login.error.missing_information';

    if (_email === '') _errors.email = 'login.error.missing_information';

    if (_password === '') _errors.password = 'login.error.missing_information';

    if (_confirm_password === '') _errors.confirm_password = 'login.error.missing_information';

    if (Object.values(_errors).length > 0) {
      setErrors(_errors);
      showLoading(false);
      return;
    }

    fetch(`${baseUrl}/register`, {
      method: 'POST',
      headers: {
        Accept: 'json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        language:intl.locale,
        username: _username,
        email: _email,
        password: _password,
        confirm_password: _confirm_password,
      }),
    })
      .then(response => response.json())
      .then(response => {
        if (response.user) {
          showSnackBar('login.success', 'success');
          onLogin(response.user);
        } else if (response.error) {
          if (response.username) setErrors({ username: response.error });
          if (response.email) setErrors({ email: response.error });
          if (response.password) setErrors({ password: response.error });
          if (response.confirm_password)
            setErrors({
              confirm_password: response.error,
            });
          if (response.missing_information)
            setErrors({
              username: response?.username ? response.error : null,
              email: response?.email ? response.error : null,
              password: response?.confirm_password ? response.error : null,
              confirm_password: response?.confirm_password ? response.error : null,
            });
        } else {
          showSnackBar('app.response.unknown', 'error');
        }
        showLoading(false);
      })
      .catch(error => {
        console.error(error);
      });
  };

  return (
    <Grid item xs={12} sm={4}>
      <Box className={classes.box}>
        <Tabs
          value={tabValue}
          onChange={handleTabChange}
          indicatorColor='primary'
          textColor='inherit'
          centered
          variant='standard'>
          <Tab label={<FormattedMessage id='app.login' />} value={formTabs.login} />
          <Tab label={<FormattedMessage id='app.register' />} value={formTabs.signUp} />
        </Tabs>

        {tabValue != null && (
          <Grid
            container
            direction='column'
            className={classes.inputContainer}
            wrap='nowrap'
            justifyContent='center'
            alignItems='center'
            component='form'
            onSubmit={handleSubmit}>
            {!forgotPassword && (
              <Grid
                container
                item
                direction='column'
                justifyContent={!forgotPassword ? 'center' : 'flex-start'}
                alignItems='stretch'
                xs={12}>
                <TextField
                  id='username'
                  name='username'
                  label={<FormattedMessage id='login.form.username' />}
                  type='text'
                  defaultValue=''
                  variant='outlined'
                  size='small'
                  margin='normal'
                  className={classes.textField}
                  onFocus={clearErrorsOnFocus}
                  onBlur={checkForError}
                  helperText={errorFor('username')}
                  error={showError('username')}
                  autoComplete={tabValue == formTabs.signUp ? 'new-password' : ''}
                />
                {tabValue === formTabs.signUp && (
                  <TextField
                    id='email'
                    name='email'
                    type='email'
                    label={<FormattedMessage id='login.form.email' />}
                    variant='outlined'
                    size='small'
                    margin='normal'
                    onFocus={clearErrorsOnFocus}
                    onBlur={checkForError}
                    className={classes.textField}
                    helperText={errorFor('email')}
                    error={showError('email')}
                    autoComplete={tabValue == formTabs.signUp ? 'new-password' : ''}
                  />
                )}
                <TextField
                  id='password'
                  name='password'
                  type={showPassword ? 'text' : 'password'}
                  label={<FormattedMessage id='login.form.password' />}
                  variant='outlined'
                  size='small'
                  margin='normal'
                  className={classes.textField}
                  onFocus={clearErrorsOnFocus}
                  onBlur={checkForError}
                  helperText={errorFor('password')}
                  error={showError('password')}
                  autoComplete={tabValue == formTabs.signUp ? 'new-password' : ''}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position='end'>
                        <IconButton aria-label='toggle password visibility' onClick={handleClickShowPassword}>
                          {showPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
                {tabValue === formTabs.signUp && (
                  <TextField
                    id='confirm_password'
                    name='confirm_password'
                    type='password'
                    label={<FormattedMessage id='login.form.confirm_password' />}
                    variant='outlined'
                    size='small'
                    margin='normal'
                    onFocus={clearErrorsOnFocus}
                    onBlur={checkForError}
                    className={classes.textField}
                    helperText={errorFor('confirm_password')}
                    error={showError('confirm_password')}
                    autoComplete={tabValue == formTabs.signUp ? 'new-password' : ''}
                  />
                )}
              </Grid>
            )}
            {forgotPassword && (
              <Grid container item direction='column' justifyContent='center' alignItems='stretch' xs={12}>
                <TextField
                  id='email'
                  name='email'
                  type='email'
                  label={<FormattedMessage id='login.form.email' />}
                  variant='outlined'
                  size='small'
                  margin='normal'
                  className={classes.textField}
                  onFocus={clearErrorsOnFocus}
                  onBlur={checkForError}
                  helperText={errorFor('email')}
                  error={showError('email')}
                />
              </Grid>
            )}
            <Grid
              container
              item
              xs={12}
              justifyContent={tabValue === formTabs.login ? 'space-evenly' : 'flex-end'}
              alignItems='center'
              wrap='nowrap'>
              {tabValue === formTabs.login && (
                <Link to='#' onClick={e => setForgotPassword(prevState => !prevState)}>
                  {forgotPassword ? (
                    <FormattedMessage id='login.form.password_found' />
                  ) : (
                    <FormattedMessage id='login.form.forgot_password' />
                  )}
                </Link>
              )}

              <Button
                type='submit'
                variant='contained'
                color='primary'
                disabled={Object.values(errors).length > 0 || isButtonDisabled}>
                {tabValue === formTabs.login ? (
                  forgotPassword ? (
                    <FormattedMessage id='app.send_mail' />
                  ) : (
                    <FormattedMessage id='app.login' />
                  )
                ) : (
                  <FormattedMessage id='app.register' />
                )}
              </Button>
            </Grid>
          </Grid>
        )}
      </Box>
    </Grid>
  );
};

export default injectIntl(withRouter(withStyles(LoginFormStyles)(LoginForm)));
