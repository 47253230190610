import { UpdateUserPreferences } from '@/Actions/';
import { baseApiUrl } from '@/Functions/';
import { faLanguage } from '@fortawesome/free-solid-svg-icons';
//import messages_sp from '../../Translations/sp.json';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Typography from '@material-ui/core/Typography';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { injectIntl, intlShape } from 'react-intl';
import { updateIntl } from 'react-intl-redux';
import { connect } from 'react-redux';
import messages_en from '../../Translations/en.json';
import messages_es from '../../Translations/es.json';
import messages_fr from '../../Translations/fr.json';
import Tooltip from '../Misc/CustomTooltip.js';

const languages = [
  {
    name: 'English',
    locale: 'en',
    messages: messages_en,
  },
  {
    name: 'Français',
    locale: 'fr',
    messages: messages_fr,
  },
  {
    name: 'Espanol',
    locale: 'es',
    messages: messages_es,
  },
];

class SelectLanguage extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      anchorEl: null,
    };

    this.handleClick = this.handleClick.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.handleLanguageClick = this.handleLanguageClick.bind(this);
  }

  handleClick(event) {
    this.setState({
      anchorEl: event.currentTarget,
    });
  }

  handleClose() {
    this.setState({
      anchorEl: null,
    });
  }

  handleLanguageClick(language) {
    if (this.props.selectedUser) {
      fetch(`${baseApiUrl}/user_preferences/language/${language.locale}`)
        .then(response => response.json())
        .then(response => {
          if (response.success) {
            this.props.setUserPreferences(response.preferences);
          } else {
            console.log(response);
          }
        })
        .catch(e => console.error(e));
    }
    this.props.changeLanguage(language.locale, language.messages);
    this.handleClose();
  }

  render() {
    let { anchorEl } = this.state;
    let { classes, intl } = this.props;

    return (
      <Grid item>
        <Tooltip title={'language.title'}>
          <Button className={classes.languageButton} variant='outlined' color='inherit' onClick={this.handleClick}>
            <FontAwesomeIcon className={classes.extendedIcon} icon={faLanguage} size='lg' />
            <Typography variant='body1' color='inherit'>
              {intl.locale}
            </Typography>
          </Button>
        </Tooltip>
        <Menu id='languageMenu' anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={this.handleClose}>
          {languages.map(language => (
            <MenuItem key={language.locale} onClick={() => this.handleLanguageClick(language)}>
              {language.name}
            </MenuItem>
          ))}
        </Menu>
      </Grid>
    );
  }
}

SelectLanguage.displayName = 'SelectLanguage';
SelectLanguage.propTypes = {
  classes: PropTypes.object.isRequired,
  changeLanguage: PropTypes.func.isRequired,
  intl: intlShape.isRequired,
};
SelectLanguage.defaultProp = {};

const mapStateToProps = state => ({
  selectedUser: state.user,
});

const mapDispatchToProps = dispatch => ({
  changeLanguage: (locale, messages) => dispatch(updateIntl({ locale, messages })),
  setUserPreferences: userPreferences => dispatch(UpdateUserPreferences(userPreferences)),
});

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(SelectLanguage));
