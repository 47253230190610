import React, { Component } from "react";
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';

import styles from '../../Themes/menu.js';

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { error: null, errorInfo: null };
  }

  componentDidCatch(error, errorInfo) {
    this.setState({
      error: error,
      errorInfo: errorInfo
    })
    console.error(error.message);
  }

  render() {
    if (this.state.errorInfo) {
      return (
        <main className={this.props.classes.content}>
          <div className={this.props.classes.toolbar}>
            <Grid container direction='column'>
              <Grid item xs={12}>
                <Typography variant='h6' gutterBottom>
                  <FormattedMessage id='error.unhandled.occurred' />
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant='body2' style={{ whiteSpace: 'pre-wrap' }}>
                  <FormattedMessage id='error.please.reload_page' />
                </Typography>
              </Grid>
            </Grid>
          </div>
        </main>
      );
    }
    return this.props.children;
  }
}

ErrorBoundary.displayName = "ErrorBoundary";
ErrorBoundary.propTypes = {
  classes: PropTypes.object.isRequired,
};
ErrorBoundary.defaultProps = {
};

export default withStyles(styles)(ErrorBoundary);
