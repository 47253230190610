import createReducer from './general.js'
import { SCENARIO_CHANGE } from '../Actions/scenario.js';

const initialState = { id: -1, name: '', comment: '' };

const scenario = (state, action) => {
  if (action.scenario) {
    return {
      ...state,
      id : action.scenario.id,
      name: action.scenario.name
    };
  }
  else {
    return initialState;
  }
}

export const scenarioReducer = createReducer(initialState, { SCENARIO_CHANGE: scenario })
