import { cloneDeep } from 'lodash';
import {
  DELETE_BUILDINGS,
  DELETE_REAL_ESTATE,
  IMPORT_REAL_ESTATES_LIST,
  INSERT_BUILDING,
  INSERT_REAL_ESTATE,
  SELECTION_REAL_ESTATE_CHANGE,
  UPDATE_BUILDING,
  UPDATE_REAL_ESTATE,
} from '../Actions/realEstate.js';
import createReducer from './general.js';

const initialState = {
  list: [],
  selected: -1,
};

const realEstate = (state = initialState, action) => {
  let newList = [];
  switch (action.type) {
    case IMPORT_REAL_ESTATES_LIST:
      return {
        ...state,
        list: [...action.payload?.realEstates],
        selected: action.payload?.realEstates?.[0]?.id || -1,
      };
    case INSERT_REAL_ESTATE:
      return {
        list: [...state.list, action.payload.realEstate],
        selected: action.payload.realEstate.id,
      };
    case DELETE_REAL_ESTATE:
      if (action.payload.id == -1) return state;
      let oldIndex = state.list.findIndex(re => re.id == action.payload.id);
      newList = state.list.filter(re => re.id != action.payload.id);
      return {
        selected: state.selected == action.payload.id ? (oldIndex > 0 ? newList[oldIndex - 1].id : -1) : state.selected,
        list: newList,
      };
    case SELECTION_REAL_ESTATE_CHANGE:
      return { ...state, selected: action.payload.id };
    case UPDATE_REAL_ESTATE: {
      let newState = _.cloneDeep(state);
      let updateIndex = newState.list.findIndex(re => re.id === action.payload.realEstate.id);
      if (updateIndex !== -1) newState.list[updateIndex] = action.payload.realEstate;
      return newState;
    }
    case INSERT_BUILDING: {
      if (action.payload?.building && state.selected != -1) {
        newList = [...state.list];
        let realEstateIndex = state.list.findIndex(realEstate => realEstate.id == state.selected);
        newList[realEstateIndex].buildings.push(action?.payload?.building);
        return { ...state, list: [...newList] };
      }
      return state;
    }
    case UPDATE_BUILDING: {
      let newRealEstateList = cloneDeep(state.list);
      let realEstateIndex = state.list.findIndex(realEstate => realEstate.id == state.selected);

      let updateIndex = newRealEstateList[realEstateIndex]?.buildings?.findIndex(
        building => action.payload.building.id == building.id
      );
      let oldBuilding = cloneDeep(newRealEstateList[realEstateIndex].buildings[updateIndex]);
      let newBuilding = {
        ...oldBuilding,
        ...action.payload.building,
      };
      newRealEstateList[realEstateIndex].buildings[updateIndex] = {
        ...newBuilding,
      };
      return { ...state, list: newRealEstateList };
    }
    case DELETE_BUILDINGS: {
      newList = _.cloneDeep(state.list);
      let realEstateIndex = newList.findIndex(realEstate => realEstate.id == state.selected);

      let newBuildings = newList[realEstateIndex]?.buildings?.filter(
        building => !action.payload.deleteBuildingsList.includes(building.id)
      );
      if (newBuildings.length === state?.list?.[realEstateIndex]?.buildings?.length) return state;

      newList[realEstateIndex].buildings = [...newBuildings];
      return { ...state, list: [...newList] };
    }
    default:
      state;
  }
};

const realEstateReducer = createReducer(initialState, {
  DELETE_BUILDINGS: realEstate,
  DELETE_REAL_ESTATE: realEstate,
  IMPORT_REAL_ESTATES_LIST: realEstate,
  INSERT_BUILDING: realEstate,
  UPDATE_BUILDING: realEstate,
  INSERT_REAL_ESTATE: realEstate,
  SELECTION_REAL_ESTATE_CHANGE: realEstate,
  UPDATE_REAL_ESTATE: realEstate,
});

export default realEstateReducer;
