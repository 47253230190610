import React from "react";
import PropTypes from "prop-types";

import { FormattedMessage } from "react-intl";
import Typography from "@material-ui/core/Typography";
import { Tooltip } from "@material-ui/core";

const CustomTooltip = ({
  title,
  formatted,
  fragment,
  children,
  handleClick,
  ...props
}) => {
  let text = fragment ? (
    title
  ) : (
    <Typography
      variant="subtitle1"
      style={{ color: "rgba(255, 255, 255, 0.87)" }}
    >
      {formatted ? <FormattedMessage id={title} /> : title}
    </Typography>
  );
  const sendClick = (e) => {
    if (typeof handleClick === "function")
    handleClick(e, title);
  };

  return (
    <Tooltip
      title={text}
      {...props}
      onClick={(e) => sendClick(e)}
    >
      {children}
    </Tooltip>
  );
};

CustomTooltip.displayName = "CustomTooltip";
CustomTooltip.propTypes = {
  title: PropTypes.node.isRequired,
  formatted: PropTypes.bool,
  fragment: PropTypes.bool,
  children: PropTypes.node.isRequired,
};
CustomTooltip.defaultProps = {
  formatted: true,
  fragment: false,
};

export default CustomTooltip;
