import { STORE_PREVIOUS } from "@/Actions/previous_state";
import createReducer from "./general";

const initialState = {};
const previous_state = (state = initialState, action) => {
  switch (action?.type) {
    case STORE_PREVIOUS:
      return action.payload;
    default:
      return initialState;
  }
};

export const previousStateReducer = createReducer(initialState, {
  STORE_PREVIOUS: previous_state,
});
