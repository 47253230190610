export const SHOW_MESSAGE = 'SHOW_MESSAGE';
export const CLEAR_NOTIFICATION = 'CLEAR_NOTIFICATION';

export const ShowNotification = (message, variant,optional=null) => ({
  type: SHOW_MESSAGE,
  payload: {
    message,
    variant,
    optional,
  },
});
export const ClearNotification = () => ({
  type: CLEAR_NOTIFICATION,
});
