import { LOGOUT, SET_REALESTATE, SET_USER } from '../Actions/user.js';
import createReducer from './general.js';

const initialState = null;

const user = (state, action) => {
  switch (action.type) {
    case SET_USER:
      if (action.user) {
        return {
          ...state,
          username: action.user.username,
          email: action.user.email,
          roles: action.user.roles,
          realestate: [...action.user?.realestate],
          preferences: { ...action.user?.preferences },
        };
      } else {
        return initialState;
      }

    case LOGOUT:
      return initialState;

    case SET_REALESTATE:
      if (action.realestate) {
        return {
          ...state,
          realestate: [...realestate],
        };
      } else {
        return initialState;
      }

    default:
      return state;
  }
};

export const userReducer = createReducer(initialState, { SET_USER: user, LOGOUT: user, SET_REALESTATE: user });
