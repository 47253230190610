import React from 'react';
import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';

const Link = (props) => (
    <RouterLink style={{textDecoration: 'none'}} {...props}>
     {props.children}
    </RouterLink>
)

Link.displayName = "Link";
Link.propTypes = {
  children: PropTypes.node.isRequired,
};
Link.defaultProps = {};

export default Link;
