import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Container, Fab } from '@material-ui/core';
import { withStyles } from '@material-ui/styles';
import classNames from 'classnames';
import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from 'react';
import ReactDOM from 'react-dom';

const styles = theme => ({
  height100: {
    height: '100% !important',
  },
  center: {
    top: '50%',
    left: '50%',
    width: '60%',
    position: 'absolute',
    transform: 'translate(-50%,-50%)',
    display: 'flex',
    flexWrap: 'nowrap',
    padding: '2.5%',
    '&>:first-child': {
      backgroundColor: theme.palette.appBackgroundColor,
    },
  },
  fullSize: {
    width: '100%',
    height: '100%',
  },
  modalContainer: {
    position: 'absolute',
    width: '100%',
    height: 'auto%',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%,-50%)',
    background: theme.palette.appBackground,
  },
  buttonClose: {
    position: 'relative',
    margin: '10px',
    top: '-10px',
    backgroundColor: theme.palette.extras.fab.danger.light,
    color: theme.palette.error.contrastText,
    '&:hover': {
      backgroundColor: theme.palette.extras.fab.danger.dark,
    },
  },
  backDrop: {
    position: 'absolute',
    margin: 0,
    inset: '0',
    background: 'rgba(0,0,0,0.4)',
    zIndex: '9000',
    width: '100%',
    height: '100%',
  },
  show: {
    visibility: 'visible',
  },
  hide: {
    visibility: 'hidden',
  },
});

const Modal = forwardRef(({ fullHeight = false, ...props }, ref) => {
  const classes = props.classes;
  const [portal, setPortal] = useState(null);
  const [display, setDisplay] = useState(false);
  const [actionControlsEnabled, setActionControlsEnabled] = useState(true);
  const [children, setChildren] = useState();
  const [containerWidth, setContainerWidth] = useState('xl');
  const [isFullSize, setIsFullSize] = useState(false);

  useImperativeHandle(ref, () => {
    return {
      isDisplayed: display,
      portal: portal,
      changeWidth: w => setContainerWidth(w),
      toggle: () => handleToggleModal(),
      isFullSize: isFullSize,
      toggleFullSize: (bool = false) => setIsFullSize(bool),
      toggleActionControls: (bool = undefined) =>
        handleToggleActionControls(bool),
      setChildren: component => setChildren(component),
    };
  });

  const handleToggleModal = e => {
    let element = portal;
    if (element) {
      element.classList.add(display ? classes.hide : classes.show);
      element.classList.remove(!display ? classes.hide : classes.show);
      setDisplay(!display);
    }
  };
  const handleToggleActionControls = bool => {
    setActionControlsEnabled(prev => (bool != undefined ? bool : !prev));
  };

  useEffect(() => {
    let domPortal = document.getElementById('portal');
    if (!domPortal) {
      domPortal = document.createElement('div');
      domPortal.setAttribute('id', 'portal');
      domPortal.classList.add(classes.hide);
      document.body.appendChild(domPortal);
    }
    setPortal(domPortal);
    return () => {
      if (portal) document.body.removeChild(portal);
    };
  }, []);

  return portal && display
    ? ReactDOM.createPortal(
        <React.Fragment>
          <Container
            className={classNames({
              [classes.backDrop]: true,
            })}
            disableGutters
            maxWidth='xl'
            onClick={e => {
              if (e.target == e.currentTarget) handleToggleModal();
            }}>
            <Container
              maxWidth={containerWidth}
              className={classNames({
                [classes.height100]: fullHeight,
                [classes.center]: true,
                [classes.fullSize]: isFullSize,
              })}>
              {children ? children : props.children}
              {!props?.childInteractionModal && actionControlsEnabled && (
                <Fab
                  onClick={handleToggleModal}
                  className={classes.buttonClose}
                  variant='circular'
                  size='small'>
                  <FontAwesomeIcon icon={faTimes} size='lg' />
                </Fab>
              )}
            </Container>
          </Container>
        </React.Fragment>,
        portal
      )
    : null;
});

export default withStyles(styles)(Modal);
