import Modal from '@/Components/Modal/Modal';
import { faChevronCircleLeft, faChevronCircleRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Grid, useMediaQuery, withStyles } from '@material-ui/core';
import ImageList from '@material-ui/core/ImageList';
import ImageListItem from '@material-ui/core/ImageListItem';
import React, { useEffect, useRef, useState } from 'react';
const ImageFolder = '/images/preview_gallery/';
const imageSourcesObject = require.context('/public/images/preview_gallery/', false, /\.(png|jpe?g|svg)$/);

const styles = theme => ({
  imagesListContainer: {
    margin: '0 10px',
    overflow: 'hidden',
  },
  btnContainer: {
    margin: '0 2px',
    zIndex: 2,
    backgroundColor: theme.palette.appBackgroundColor,
  },
  arrow: {
    verticalAlign: 'middle',
    textAlign: 'center',
  },
  scrollButton: {
    borderRadius: '50%',
  },
  buttonDisabled: {
    cursor: 'not-allowed !important',
    filter: 'opacity(0.4)',
  },
  imageList: {
    flexWrap: 'nowrap',
    transform: 'translateZ(0)',
  },
  imageListItemRoot: {
    padding: '10px',
  },
  imgFullHeight: {
    height: '100% !important',
    top: '50%',
    transform: 'translate(-50%,-50%) !important',
  },
  image: {
    padding: '10px',
    boxShadow: '0 0 2px 3px rgba(0,0,0,0.3)',
    objectFit: 'contain',
    borderRadius: '5px',
  },
  imagePreview: {
    width: '100%',
    objectFit: 'contain',
    margin: '0 20px',
    height: '100%',
    backgroundColor: 'transparent !important',
  },
});

const PreviewGallery = ({ classes, ...props }) => {
  const imageListRef = useRef();
  const modalRef = useRef();
  const [images, setImages] = useState([]);
  const [disableRightScroll, setDisableRightScroll] = useState(false);
  const [disableLeftScroll, setDisableLeftScroll] = useState(true);
  useEffect(() => {
    if (imageSourcesObject)
      (() => {
        let initializeImages = [];
        imageSourcesObject.keys().map(item => {
          let fileName = item.replace('./', ImageFolder);
          initializeImages.push(fileName);
        });
        setImages(initializeImages);
      })();
  }, []);

  const isXsScreen = useMediaQuery(theme => theme.breakpoints.only('xs'));
  const isSmScreen = useMediaQuery(theme => theme.breakpoints.only('sm'));

  const handleScrollClick = direction => {
    if (imageListRef.current.children.length > 0) {
      let offset = imageListRef.current.children[0].offsetWidth;
      switch (direction) {
        case 'left':
          if (imageListRef.current.scrollLeft <= 0) {
            imageListRef.current.scrollLeft = 0;
            setDisableLeftScroll(() => true);
          } else {
            imageListRef.current.scrollLeft -= offset;
            if (imageListRef.current.scrollLeft <= 0) setDisableLeftScroll(() => true);
            if (disableRightScroll) setDisableRightScroll(() => false);
          }
          break;
        case 'right':
          if (imageListRef.current.getBoundingClientRect().right >= imageListRef.current.scrollLeft + offset) {
            imageListRef.current.scrollLeft += offset;
            if (disableLeftScroll) setDisableLeftScroll(() => false);
            if (imageListRef.current.getBoundingClientRect().right < imageListRef.current.scrollLeft + offset)
              setDisableRightScroll(() => true);
          }
          break;
        default:
          break;
      }
    }
  };
  const showImagePreview = imagePath => {
    if (modalRef?.current) {
      let picturePreview = React.createElement('img', {
        src: imagePath,
        className: classes.imagePreview,
      });
      modalRef.current.toggle();
      modalRef.current.setChildren(picturePreview);
      if (isXsScreen || isSmScreen) modalRef?.current?.toggleFullSize(true);
    }
  };

  useEffect(() => {
    if (modalRef?.current?.isDisplayed)
      if (isXsScreen || isSmScreen) {
        modalRef?.current?.toggleFullSize(true);
      } else {
        if (modalRef?.current?.isFullSize) modalRef.current.toggleFullSize(false);
      }
  }, [isXsScreen, isSmScreen, modalRef]);

  return (
    <Grid container item xs={12} justifyContent='center' wrap='nowrap'>
      <Grid container item xs={1} className={classes.btnContainer} justifyContent='center' alignItems='center'>
        <Button
          className={classes.scrollButton}
          classes={{
            disabled: classes.buttonDisabled,
          }}
          disabled={disableLeftScroll}
          onClick={() => handleScrollClick('left')}>
          <FontAwesomeIcon className={classes.arrow} icon={faChevronCircleLeft} size='4x' color='gray' />
        </Button>
      </Grid>
      <Grid container item xs={9} className={classes.imagesListContainer} justifyContent='center' wrap='nowrap'>
        <ImageList
          id='preview-gallery'
          ref={imageListRef}
          className={classes.imageList}
          rowHeight={isXsScreen ? 200 : isSmScreen ? 225 : 250}
          cols={isXsScreen ? 1 : isSmScreen ? 2 : 3}
          gap={25}>
          {React.Children.toArray(
            images.map(item => (
              <ImageListItem
                classes={{
                  item: classes.imageListItemRoot,
                  imgFullHeight: classes.imgFullHeight,
                }}>
                <img
                  width='100%'
                  height='100%'
                  className={classes.image}
                  src={item}
                  onClick={() => showImagePreview(item)}
                />
              </ImageListItem>
            ))
          )}
        </ImageList>
      </Grid>
      <Grid container item xs={1} justifyContent='center' alignItems='center' className={classes.btnContainer}>
        <Button
          className={classes.scrollButton}
          classes={{
            disabled: classes.buttonDisabled,
          }}
          disabled={disableRightScroll}
          onClick={() => handleScrollClick('right')}>
          <FontAwesomeIcon className={classes.arrow} icon={faChevronCircleRight} size='4x' color='gray' />
        </Button>
      </Grid>

      <Modal ref={modalRef} />
    </Grid>
  );
};

export default withStyles(styles)(PreviewGallery);
