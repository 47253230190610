export const CHANGE_LOADING = 'CHANGE_LOADING';
export const CHANGE_PAGE_ITEM_LOADING = 'CHANGE_PAGE_ITEM_LOADING';
export const changeLoading = (isLoading = false, location = null, pageItemLoading = false) => ({
  type: CHANGE_LOADING,
  payload: { isLoading, location, pageItemLoading },
});
export const changePageItemLoading = (pageItemLoading = false) => ({
  type: CHANGE_PAGE_ITEM_LOADING,
  payload: { pageItemLoading },
});
