export const UpdateBuildingStructureWorkerActions = {
  ADD_TO_CHANGES_LIST: 'ADD_TO_CHANGES_LIST',
  INITIALIZE_UPDATES_LIST: 'INITIALIZE_UPDATES_LIST',
  UPDATE_START: 'UPDATE_START',
  UPDATE_FINISHED: 'UPDATE_FINISHED',
  FETCH_CHANGES_LIST: 'FETCH_CHANGES_LIST',
  STORE: 'STORE',
  STORE_FINISHED: 'STORE_FINISHED',
};
export const UPDATE_BUILDING_WORKER_SPAWN = 'SPAWN_UPDATE_BUILDING_WORKER';
export const UPDATE_BUILDING_WORKER_READY = 'READY_UPDATE_BUILDING_WORKER';
export const errorArray = ['error', 'errors', 'otherError', 'ERROR'];
export const FormatCost = (cost, decimals = 2) => {
  return RoundNumber(cost, decimals)
    .toString()
    .replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
};

export const RoundNumber = (number, decimals = 2) =>
  !isNaN(number) ? Math.round(number / 10 ** decimals) * 10 ** decimals : 0;

export const errorOccurred = error => console.error(error);
export const isDevelopmentBuild = () => process.env.REACT_APP_ENV === 'dev';
export const isDevelopmentServerBuild = () => process.env.REACT_APP_ENV === 'dev-server';

export const checkValueEmptyStringConvertToNull = (checkValue, baseObject) => {
  if (typeof checkValue === 'string') {
    if (checkValue.length == 0) {
      checkValue = null;
      return checkValue;
    } else if (typeof baseObject === 'number') {
      checkValue = Number(checkValue);
      return checkValue;
    }
  } else if (checkValue instanceof Object && !Array.isArray(checkValue)) {
    Object.keys(checkValue).forEach(k => {
      checkValue[k] = checkValueEmptyStringConvertToNull(checkValue[k], baseObject[k]);
    });
    return checkValue;
  } else {
    if (Array.isArray(checkValue))
      for (let index = 0; index < checkValue.length; index++) {
        checkValue[index] = checkValueEmptyStringConvertToNull(checkValue[index], baseObject[index]);
      }
    return checkValue;
  }
  return checkValue;
};
export const checkValuesEquality = (checkValue, baseObject) => {
  let result = [];
  if (typeof checkValue === 'string' || typeof checkValue === 'number') {
    result.push(checkValue === baseObject);
  } else if (checkValue instanceof Object && !Array.isArray(checkValue)) {
    Object.keys(checkValue).forEach(k => {
      result.push(checkValuesEquality(checkValue[k], baseObject[k]));
    });
  } else {
    if (Array.isArray(checkValue))
      for (let index = 0; index < checkValue.length; index++) {
        result.push(checkValuesEquality(checkValue[index], baseObject[index]));
      }
  }
  return result.every(Boolean);
};

