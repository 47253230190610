import { baseApiUrl } from '.';
import { RoundNumber } from './Utility';

export const interventionABCD = 'abcd'.split('');
export const improvementsSTUV = 'stuv'.split('');
export const filterDiagnosisGroups = selectedBuilding =>
  selectedBuilding?.macroUnits?.filter(group => group?.units?.filter(u => u?.types?.length > 0)?.length > 0) ?? [];

export function getActiveTypes(types) {
  return types.filter(type => type.diagnoses[0].model.code !== '');
}

export function getUnactiveTypes(types) {
  return types.filter(type => type.diagnoses[0].model.code === '');
}
export function getElementTypes(types, groupId, elementId) {
  return types.filter(type => {
    return (
      (type.macroUnitId === groupId && type.unitId === elementId) ||
      (type.macroUnitId === groupId && elementId === -1) ||
      (groupId === -1 && elementId === -1)
    );
  });
}

export function setFillState(groups) {
   return groups.map(group => {
    group.active = false;
    group.hasAction = true;
    group.units.map(unit => {
      unit.active = false;
      unit.hasAction = true;
      let hasActiveType = unit.types.some(type => {
        return type.diagnoses[0].model.code !== '';
      });
      if (hasActiveType) {
        group.active = true;
        unit.active = true;
      }
    });
     return group;
  });
}

export function getTypeScenarioIndex(type, scenario, creation = true) {
  let index = -1;
  if (type.scenarios) {
    index = type.scenarios.findIndex(s => s.id === scenario.id);

    if (creation && index === -1) {
      type.scenarios.push({ ...scenario, interventions: [] });
      index = type.scenarios.length - 1;
    }
  }
  return index;
}
export const getAdditionalWorkTitle = (type, intervention) => {
  return (
    type?.model?.interventionModels?.find(ivm => ivm?.code?.slice(-1) == getInterventionCodeCharacter(intervention))
      ?.translation[0]?.title || ''
  );
};

export function getInterventionModelCodeCharacter(interventionModel) {
  return interventionModel?.code?.slice(-1) ?? '';
}
export function getInterventionCodeCharacter(intervention) {
  return intervention?.model?.code?.slice(-1) ?? '';
}

export function isInterventionABCD(intervention) {
  let abcdArray = ['a', 'b', 'c', 'd'];
  if (intervention?.model?.code === undefined) throw new Error(intervention);
  return abcdArray?.includes(intervention?.model?.code?.substr(-1, 1));
}

export function indexInterventionABCD(scenario) {
  return (
    scenario?.interventions?.findIndex(intervention => {
      return isInterventionABCD(intervention);
    }) ?? -1
  );
}
export function getInterventionIndexABCD(intervention) {
  return interventionABCD?.findIndex(code => code == intervention?.model?.code?.slice(-1));
}
export function getInterventionIndexSTUV(improvement) {
  return interventionABCD?.findIndex(code => code == improvement?.model?.code?.slice(-1));
}
export function isInterventionSTUV(intervention) {
  let stuvArray = ['s', 't', 'u', 'v'];

  return stuvArray.includes(intervention?.model?.code?.slice(-1));
}

export function indexInterventionSTUV(scenario) {
  return scenario.interventions.findIndex(intervention => {
    return isInterventionSTUV(intervention);
  });
}
export function checkIfTypeHasAdditionalWorks(type) {
  return (
    type?.model?.interventionModels?.filter(interventionModel =>
      improvementsSTUV.includes(getInterventionModelCodeCharacter(interventionModel))
    )?.length > 0
  );
}

export function createIntervention(type, indexModel) {
  let intervention = {
    id: -1,
    text: type.model.interventionModels[indexModel].description,
    pct_cost: 100,
    priority: 0,
    cost: (type.model.interventionModels[indexModel].cost * type.diagnoses[0].pct_type) / 100,
    model: {
      id: type.model.interventionModels[indexModel].id,
      code: type.model.interventionModels[indexModel].code,
    },
    action: { id: -1 },
  };
  return intervention;
}

export function updateTypeCosts(type, scenarioIndex = -1) {
  type?.scenarios?.forEach((scenario, index, arr) => {
    if (scenarioIndex === -1 || scenarioIndex === index) {
      let scenarioCost = 0;
      scenario?.interventions?.map(intervention => {
        let modelIndex = type.model.interventionModels.findIndex(m => m.code == intervention.model.code);
        intervention.cost =
          (((type.diagnoses[0].pct_type / 100) * intervention.pct_cost) / 100) *
          (type?.model?.interventionModels?.[modelIndex]?.cost || 0);
        scenarioCost = RoundNumber(scenarioCost) + RoundNumber(intervention.cost);
        return intervention;
      });
      if (scenario?.interventions?.length == 0) scenario.interventions = [];
      scenario.costType = scenarioCost;
      arr[index] = scenario;
    }
  });

  return type;
}

export async function UPDATE_TYPE(type, options = {}, validationScenarios = null) {
  let newScenarios = [];
  if (validationScenarios) {
    let differences = _.xorBy(type.scenarios, validationScenarios, 'id');
    differences.forEach(difference => {
      let differenceIndex = type.scenarios.findIndex(scenario => scenario.id == difference.id);
      if (differenceIndex != -1) {
        type.scenarios.splice(differenceIndex, 1);
      } else {
        type.scenarios.splice(-1, 0, difference);
      }
    });
  }

  newScenarios = type.scenarios.map(scenario => {
    let interventions = [];
    if (scenario)
      if (scenario.interventions)
        if (scenario.interventions.length > 0)
          scenario.interventions = scenario.interventions.map(intervention => {
            let i = {
              id: intervention.id,
              text: intervention.text,
              pct_cost: new Number(intervention.pct_cost || 0),
              priority: new Number(intervention.priority || 0),
              cost: new Number(intervention.cost || 0),
              model: intervention.model.id,
              action: intervention.action ? intervention.action.id : null,
            };
            return i;
          });

    if (scenario.interventions.length > 0) interventions = scenario.interventions;
    return {
      id: scenario.id,
      interventions: interventions,
    };
  });

  const jsonResponse = await fetch(`${baseApiUrl}/type/${type.id}`, {
    method: 'PUT',
    headers: {
      Accept: 'json',
      'Content-Type': 'json',
    },
    keepalive: true,
    body: JSON.stringify({
      language: options.language,
      normalizeGroup: 'diagnosis'.valueOf(),
      diagnoses: [
        {
          id: type.diagnoses[0].id,
          description: type.diagnoses[0].description,
          text: type.diagnoses[0].text,
          pct_type: new Number(type.diagnoses[0].pct_type),
          comment: type.diagnoses[0].comment,
          model: type.diagnoses[0].model.id,
        },
      ],
      scenarios: newScenarios,
    }),
  });
  const response = await jsonResponse.json();
  return response;
}
