import { CLEAR_NOTIFICATION, SHOW_MESSAGE } from '@/Actions/notification';
import createReducer from './general';

const initialState = {
  message: '',
  variant: '',
  optional: '',
};
const notificationHandler = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case SHOW_MESSAGE:
      let { message, variant, optional } = payload;
      return { message, variant, optional };
    case CLEAR_NOTIFICATION:
      return initialState;
    default:
      return state;
  }
};
const notificationReducer = createReducer(initialState, {
  SHOW_MESSAGE: notificationHandler,
  CLEAR_NOTIFICATION: notificationHandler,
});
export default notificationReducer;
