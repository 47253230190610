export const UPDATE_REPORTS_LIST = "UPDATE_REPORTS_LIST";
export const UPDATE_REPORT = "UPDATE_REPORT";
export const CHANGE_SELECTED_REPORT = "CHANGE_SELECTED_REPORT";
export const INSERT_REPORT = "INSERT_REPORT";
export const DELETE_REPORT = "DELETE_REPORT";

export const changeSelectedReport = selected => ({
  type: CHANGE_SELECTED_REPORT,
  selected,
});

export const insertReport = report => ({
  type: INSERT_REPORT,
  report,
  selected: report.id,
});

export const updateReport = (report, index = -1) => ({
  type: UPDATE_REPORT,
  report,
});

export const deleteReport = (index=-1) => ({
  type: DELETE_REPORT,
  index,
});

export const updateReportsList = list => ({
  type: UPDATE_REPORTS_LIST,
  reportsList: list,
});
