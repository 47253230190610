import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { makeStyles } from '@material-ui/core';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import PropTypes from 'prop-types';
import React from 'react';
import { useSelector } from 'react-redux';
import Tooltip from '../Misc/CustomTooltip.js';
import Link from '../Misc/Link.js';

const styles = theme => ({
  center: {
    margin: '0 auto',
  },
});
const useStyles = makeStyles(styles);

const PageMenuItem = ({ icon, path, titleId, disabled, selected }) => {
  const classes = useStyles();
  const freezeRouting = useSelector(state => state.freezeRouting);

  if (disabled || freezeRouting.isFrozen) {
    return (
      <ListItem button key={titleId} disabled={disabled || freezeRouting.isFrozen} selected={selected} disableGutters>
        <ListItemIcon>
          <FontAwesomeIcon icon={icon} size='lg' className={classes.center} />
        </ListItemIcon>
      </ListItem>
    );
  } else {
    return (
      <Link to={path}>
        <Tooltip title={titleId}>
          <ListItem button key={titleId} disabled={disabled} selected={selected} disableGutters>
            <ListItemIcon>
              <FontAwesomeIcon icon={icon} size='lg' className={classes.center} />
            </ListItemIcon>
          </ListItem>
        </Tooltip>
      </Link>
    );
  }
};

PageMenuItem.displayName = 'PageMenuItem';
PageMenuItem.propTypes = {
  icon: PropTypes.object.isRequired,
  path: PropTypes.string.isRequired,
  titleId: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  selected: PropTypes.bool.isRequired,
};
PageMenuItem.defaultProps = {};

export default PageMenuItem;
