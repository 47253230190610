import Main from '@/Components/Main';
import '@/Fonts/AvenirNext-UltraLight.ttf';
import '@/Fonts/AvenirNextLTPro-Bold.otf';
import '@/Fonts/AvenirNextLTPro-It.otf';
import '@/Fonts/AvenirNextLTPro-Regular.otf';
import rootReducer from '@/Reducers/index.js';
import theme from '@/Themes/main.js';
import messages_en from '@/Translations/en.json';
import { ThemeProvider as MuiThemeProvider } from '@material-ui/core/styles';
import React from 'react';
import ReactDOM from 'react-dom';
import { addLocaleData } from 'react-intl';
import { IntlProvider } from 'react-intl-redux';
import en from 'react-intl/locale-data/en';
import es from 'react-intl/locale-data/es';
import fr from 'react-intl/locale-data/fr';
import { Provider } from 'react-redux';
import { HashRouter } from 'react-router-dom';
import { applyMiddleware, compose, createStore } from 'redux';
import { isDevelopmentBuild, isDevelopmentServerBuild } from './Functions/Utility';
const thunk = require('redux-thunk').default;

const composedEnhancer = compose(
  applyMiddleware(thunk),
  isDevelopmentBuild() || isDevelopmentServerBuild()
    ? window.__REDUX_DEVTOOLS_EXTENSION__
      ? window.__REDUX_DEVTOOLS_EXTENSION__()
      : f => f
    : f => f
);
addLocaleData([...en, ...fr, ...es]);
const App = () => {
  const initialState = {
    intl: {
      defaultLocale: 'en',
      defaultMessages: messages_en,
      locale: 'en',
      messages: messages_en,
    },
  };
  const store = createStore(rootReducer, initialState, composedEnhancer);
  return (
    <MuiThemeProvider theme={theme}>
      <Provider store={store}>
        <IntlProvider>
          <HashRouter>
            <Main />
          </HashRouter>
        </IntlProvider>
      </Provider>
    </MuiThemeProvider>
  );
};

ReactDOM.render(<App />, document.getElementById('root'));
