import { FREEZE_ROUTING, UNFREEZE_ROUTING } from '@/Actions/freezeRouting';
import createReducer from './general';

const initialState = {
  isFrozen: false,
};
const freezeRoutingHandler = (state = initialState, action) => {
  const { type } = action;
  switch (type) {
    case FREEZE_ROUTING:
      return {
        isFrozen: true,
      };
    case UNFREEZE_ROUTING:
      return initialState;
    default:
      return state;
  }
};
const freezeRoutingReducer = createReducer(initialState, {
  FREEZE_ROUTING: freezeRoutingHandler,
  UNFREEZE_ROUTING: freezeRoutingHandler,
});
export default freezeRoutingReducer;
