import { menuChange } from '@/Actions/menu';
import { setUser } from '@/Actions/user';
import { baseApiUrl } from '@/Functions/';
import Pages from '@/Structure/Pages';
import HomePageStyles from '@/Themes/homePage';
import {
    Box,
    CircularProgress,
    Container,
    Dialog,
    DialogContent,
    Grid,
    Typography,
    withStyles
} from '@material-ui/core';
import classNames from 'classnames';
import React, { useEffect, useRef, useState } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { updateIntl } from 'react-intl-redux';
import { useDispatch, useSelector } from 'react-redux';
import ErrorBoundary from '../Misc/ErrorBoundary';
import CustomSnackbar from '../Misc/Snackbar';
import LoginForm from './LoginForm';
import PlatformDescription from './PlatformDescription';
import PreviewGallery from './PreviewGallery';
import SignedIn from './SignedIn';

const HomePage = ({ classes, ...props }) => {
  const showGallery = false;

  const [loading, setLoading] = useState(false);
  const [loadingMessage, setLoadingMessage] = useState('app.wait_for_login');
  const selectedUser = useSelector(state => state.user);
  const dispatch = useDispatch();
  const snackbarRef = useRef();
  useEffect(() => {
    dispatch(menuChange(Pages.home.path));
    if (!selectedUser) {
      getUser();
    }
  }, []);

  const getUser = () => {
    fetch(baseApiUrl + '/check_user', {
      method: 'GET',
    })
      .then(response => response.json())
      .then(response => {
        if (response.user) {
          let user = response.user;
          let { intl } = props;
          dispatch(setUser(user));
          if (user?.preferences?.language != intl.locale) {
            const language = user.preferences.language;
            dispatch(updateIntl({ locale: language, messages: require(`@/Translations/${language}.json`) }));
          }
        }
      })
      .catch(error => {
        console.error(error);
      });
  };

  const showLoading = shouldLoad => setLoading(shouldLoad);

  const _showSnackBar = (message, variant) => {
    if (message && variant) if (snackbarRef && snackbarRef.current) snackbarRef.current.openSnackBar(message, variant);
  };

  return (
    <ErrorBoundary>
      <Grid container item className={classes.container} direction='column' wrap='nowrap' alignItems='center' xs={12}>
        <Grid item xs={12}>
          <Typography variant='h2' className={classes.logo}>
            {props.intl
              .formatMessage({ id: 'app.title' })
              .replace(/\B([a-z](?=[A-Z])|[A-Z](?=[a-z]))/g, '$1 ')
              .split(' ')
              .map((word, index, arr) => (
                <span
                  key={`app.title_${index}`}
                  className={index === arr.length - 1 ? classes.fontLight : classes.fontBold}>
                  {word}
                </span>
              ))}
          </Typography>
        </Grid>
        {loading && (
          <Dialog open={loading} maxWidth='xs'>
            <DialogContent>
              <Box>
                <Grid container direction='column' justifyContent='space-between' alignItems='center'>
                  <CircularProgress className={classes.greenColor} size='100px' />
                  {loadingMessage&&<Typography
                    variant='h5'
                    className={classNames({ [classes.loginText]: true, [classes.greenColor]: true })}>
                     <FormattedMessage id={loadingMessage} />
                  </Typography>}
                </Grid>
              </Box>
            </DialogContent>
          </Dialog>
        )}
        {selectedUser ? (
          <SignedIn showSnackBar={_showSnackBar} />
        ) : (
          <LoginForm
            showSnackBar={_showSnackBar}
            showLoading={showLoading}
            isLoading={loading}
            setLoadingMessage={setLoadingMessage}
          />
        )}
        <Container maxWidth='xl' className={classes.bottomContainer}>
          {showGallery && <PreviewGallery />}
          <PlatformDescription />
        </Container>
      </Grid>

      <CustomSnackbar ref={snackbarRef} />
    </ErrorBoundary>
  );
};
export default injectIntl(withStyles(HomePageStyles)(HomePage));
