import { CircularProgress, makeStyles } from '@material-ui/core';
import Divider from '@material-ui/core/Divider';
import List from '@material-ui/core/List';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { getAccess } from '../../Functions/General.js';
import pages from '../../Structure/Pages.js';
import roles from '../../Structure/Roles.js';
import PageMenuItem from './PageMenuItem.js';
const styles = theme => ({
  center: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    paddingTop: '8px',
    paddingBottom: '8px',
  },
  progressBar: {
    color: theme.palette.primary.main,
  },
});
const useStyles = makeStyles(styles);
const PageMenu = ({ user, building, realEstates, scenario, selectedMenu, pageItemLoading }) => {
  //SET THIS TO FALSE TO SHOW THE MENUS FOR LATER USE, MAYBE ADD ROLE.SUPER_ADMIN ACCESS
  const disablePages = true;
  const classes = useStyles();
  return (
    <List>
      <PageMenuItem
        key={pages.home.titleId}
        path={pages.home.path}
        titleId={pages.home.titleId}
        icon={pages.home.icon}
        selected={selectedMenu === pages.home.path}
      />

      {getAccess(user, roles.user) && (
        <PageMenuItem
          key={pages.realEstates.titleId}
          path={pages.realEstates.path}
          titleId={pages.realEstates.titleId}
          icon={pages.realEstates.icon}
          disabled={!user}
          selected={selectedMenu === pages.realEstates.path}
        />
      )}
      {getAccess(user, roles.user) && building.id != -1 && (
        <PageMenuItem
          key={pages.building.titleId}
          path={pages.building.path}
          titleId={pages.building.titleId}
          icon={pages.building.icon}
          disabled={!user ? true : false}
          selected={selectedMenu === pages.building.path}
        />
      )}

      {pageItemLoading ? (
        <div className={classes.center}>
          <CircularProgress
            size='30px'
            className={classNames({
              [classes.progressBar]: true,
            })}
          />
        </div>
      ) : (
        <>
          {getAccess(user, roles.epiqr_user) && building.id != -1 && (
            <>
              {'energyLabels' in building && (
                <>
                  <Divider />
                  <PageMenuItem
                    key={pages.energy_check.titleId}
                    path={pages.energy_check.path}
                    titleId={pages.energy_check.titleId}
                    icon={pages.energy_check.icon}
                    disabled={!user || building.id === -1 || !('energyLabels' in building)}
                    selected={selectedMenu === pages.energy_check.path}
                  />
                </>
              )}
              {'macroUnits' in building && (
                <>
                  <Divider />
                  <PageMenuItem
                    key={pages.diagnosis.titleId}
                    path={pages.diagnosis.path}
                    titleId={pages.diagnosis.titleId}
                    icon={pages.diagnosis.icon}
                    disabled={!user || building.id === -1 || !('macroUnits' in building)}
                    selected={selectedMenu === pages.diagnosis.path}
                  />
                  <PageMenuItem
                    key={pages.summary.titleId}
                    path={pages.summary.path}
                    titleId={pages.summary.titleId}
                    icon={pages.summary.icon}
                    disabled={!user || building.id === -1 || !('macroUnits' in building)}
                    selected={selectedMenu === pages.summary.path}
                  />
                </>
              )}
              <Divider />
            </>
          )}

          {getAccess(user, roles.user) &&
            user.preferences.RAT_Functions &&
            building?.macroUnits &&
            building?.actions && (
              <PageMenuItem
                key={pages.actions.titleId}
                path={pages.actions.path}
                titleId={pages.actions.titleId}
                icon={pages.actions.icon}
                disabled={!user || building.id === -1 ? true : false}
                selected={selectedMenu === pages.actions.path}
              />
            )}

          {getAccess(user, roles.user) &&
            user.preferences.RAT_Functions &&
            building?.macroUnits &&
            building?.scenariosPageIncluded && (
              <PageMenuItem
                key={pages.scenarios.titleId}
                path={pages.scenarios.path}
                titleId={pages.scenarios.titleId}
                icon={pages.scenarios.icon}
                disabled={!user || building.id === -1 ? true : false}
                selected={selectedMenu === pages.scenarios.path}
              />
            )}
        </>
      )}

      {/*<MenuListItem
         key={pages.chooseActions.titleId}
         path={pages.chooseActions.path}
         titleId={pages.chooseActions.titleId}
         icon={pages.chooseActions.icon}
         disabled={!user || building.id === -1 || scenario.id === -1 ? true : false}
         selected={selectedMenu === pages.chooseActions.path}
       />*/}
      {!disablePages && getAccess(user, roles.epiqr_user) && building.id != -1 && (
        <PageMenuItem
          key={pages.reports.titleId}
          path={pages.reports.path}
          titleId={pages.reports.titleId}
          icon={pages.reports.icon}
          disabled={!user || building.id === -1 ? true : false}
          selected={selectedMenu === pages.reports.path}
        />
      )}
      <Divider />
      {!disablePages && (
        <PageMenuItem
          key={pages.contact.titleId}
          path={pages.contact.path}
          titleId={pages.contact.titleId}
          icon={pages.contact.icon}
          selected={selectedMenu === pages.contact.path}
        />
      )}
    </List>
  );
};

PageMenu.displayName = 'PageMenu';
PageMenu.propTypes = {
  user: PropTypes.object,
  building: PropTypes.shape({
    id: PropTypes.number.isRequired,
    picture: PropTypes.string,
    name: PropTypes.string.isRequired,
    address: PropTypes.shape({
      address1: PropTypes.string,
      address2: PropTypes.string,
      address3: PropTypes.string,
      zip: PropTypes.number,
      town: PropTypes.string,
      country: PropTypes.string,
    }),
  }).isRequired,
  scenario: PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
  }).isRequired,
  selectedMenu: PropTypes.string.isRequired,
};
PageMenu.defaultProps = {};

const mapStateToProps = state => ({
  user: state.user,
  realEstates: state.realEstates,
  building: state.building,
  scenario: state.scenario,
  selectedMenu: state.selectedMenu,
  pageItemLoading: state.loading.pageItemLoading,
});

export default connect(mapStateToProps)(injectIntl(PageMenu));
