import { changeLoading } from './loading';

export const BUILDING_CHANGE = 'BUILDING_CHANGE';
export const BUILDING_CLEAR = 'BUILDING_CLEAR';
export const BUILDING_UPDATE = 'BUILDING_UPDATE';
export const BUILDING_UPDATE_STRUCTURE = 'BUILDING_UPDATE_STRUCTURE';
export const STRUCTURE_TYPE__MACRO = 'MACRO';
export const STRUCTURE_TYPE__UNIT = 'UNIT';
export const STRUCTURE_TYPE__TYPE = 'TYPE';
export const UPDATE_BUILDING_PICTURE = 'UPDATE_BUILDING_PICTURE';

export const UPDATE_MACRO_UNIT_ACTIVE_STATE = 'UPDATE_MACRO_UNIT_ACTIVE_STATE';

export const UPDATE_CONSUMPTION_PRODUCTION = 'UPDATE_CONSUMPTION_PRODUCTION';
export const DELETE_CONSUMPTION_PRODUCTION = 'DELETE_CONSUMPTION_PRODUCTION';

export const INSERT_ACTIONS = 'INSERT_ACTIONS';
export const UPDATE_ACTION = 'UPDATE_ACTION';
export const DELETE_ACTIONS = 'DELETE_ACTIONS';

export const INSERT_SCENARIO = 'INSERT_SCENARIO';
export const UPDATE_SCENARIOS = 'UPDATE_SCENARIOS';
export const DELETE_SCENARIO = 'DELETE_SCENARIO';

export const UPDATE_SPECIFIC_ENERGY_LABEL = 'UPDATE_SPECIFIC_ENERGY_LABEL';

export const buildingChange = (building = null) => ({
  type: BUILDING_CHANGE,
  payload: { building },
});
export const changeEnergyLabel = (macroUnitIdx, change) => ({
  type: UPDATE_SPECIFIC_ENERGY_LABEL,
  payload: {
    macroUnitIdx,
    change,
  },
});

export const buildingUpdate = (building, shouldRecalculate = false, changedCoefDims = null) => ({
  type: BUILDING_UPDATE,
  payload: {
    building,
    shouldRecalculate,
    changedCoefDims,
  },
});
export const buildingUpdateAsync = (building, shouldRecalculate = false, changedCoefDims = null) => {
  return function (dispatch, getState) {
    let setLoad = new Promise((res, rej) => {
      dispatch(changeLoading(true));
      res();
    });
    let setData = new Promise((res, rej) => {
      dispatch(buildingUpdate(building, shouldRecalculate, changedCoefDims));
      res();
    });
    let setLoadFalse = new Promise((res, rej) => {
      setTimeout(() => {
        dispatch(changeLoading(false));
        res();
      }, 400);
    });
    setLoad.then(res => setData.then(res => setLoadFalse));
  };
};

export const buildingUpdateStructure = change => ({
  type: BUILDING_UPDATE_STRUCTURE,
  payload: {
    change,
  },
});
export const updateBuildingPicture = path => ({
  type: UPDATE_BUILDING_PICTURE,
  payload: { picture: path },
});
export const updateConsumptionProduction = consumption_production => ({
  type: UPDATE_CONSUMPTION_PRODUCTION,
  payload: { consumption_production },
});
export const deleteConsumptionProduction = deleteId => ({
  type: DELETE_CONSUMPTION_PRODUCTION,
  payload: { deleteId },
});

export const UpdateActions = (...actions) => ({
  type: UPDATE_ACTION,
  payload: {
    actions,
  },
});
export const DeleteActions = (...actionIds) => ({
  type: DELETE_ACTIONS,
  payload: {
    actionIds,
  },
});
export const InsertActions = (...actions) => ({
  type: INSERT_ACTIONS,
  payload: {
    actions,
  },
});
export const UpdateMacroUnitActiveState = (active, macroUnitId) => ({
  type: UPDATE_MACRO_UNIT_ACTIVE_STATE,
  payload: {
    active,
    macroUnitId,
  },
});

export const UpdateScenarios = (...scenarios) => ({
  type: UPDATE_SCENARIOS,
  payload: {
    scenarios,
  },
});
export const DeleteScenario = scenarioId => ({
  type: DELETE_SCENARIO,
  payload: {
    scenarioId,
  },
});
export const InsertScenario = scenario => ({
  type: INSERT_SCENARIO,
  payload: {
    scenario,
  },
});
