import { buildingUpdateStructure } from '@/Actions/building';
import Header from '@/Components/Header/Header.js';
import CustomSnackbar from '@/Components/Misc/Snackbar.js';
import Page from '@/Components/Page.js';
import { getAccess } from '@/Functions/General';
import {
  isDevelopmentBuild,
  UpdateBuildingStructureWorkerActions,
  UPDATE_BUILDING_WORKER_READY,
  UPDATE_BUILDING_WORKER_SPAWN,
} from '@/Functions/Utility';
import pages from '@/Structure/Pages.js';
import roles from '@/Structure/Roles';
import styles from '@/Themes/menu.js';
import Worker from '@/Workers/UpdateBuildingStructure.worker';
import CssBaseline from '@material-ui/core/CssBaseline';
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import React, { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';

const Main = ({ classes, menuChange, ...props }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const appSnackbarRef = useRef();
  const selectedIsLoading = useSelector(state => state.loading);
  const selectedNotification = useSelector(state => state.notification);
  const selectedBuilding = useSelector(state => state.building);
  const selectedUser = useSelector(state => state.user);
  const selectedMenu = useSelector(state => state.selectedMenu);
  const selectedBuildingRef = useRef(selectedBuilding);
  const selectedUserRef = useRef(selectedUser);

  useEffect(() => {
    selectedBuildingRef.current = selectedBuilding;
    selectedUserRef.current = selectedUser;
  }, [selectedBuilding, selectedUser]);
  function getSelectedBuilding() {
    return selectedBuildingRef.current;
  }
  function getSelectedUser() {
    return selectedUserRef.current;
  }

  function goToShortcuts(e) {
    let building = getSelectedBuilding();
    let user = getSelectedUser();
    if (
      e.target instanceof HTMLInputElement ||
      e.target instanceof HTMLTextAreaElement ||
      e.target instanceof HTMLTableCellElement
    )
      return e;
    const { type, repeat, isComposing, altKey, shiftKey, key } = e;
    if (repeat) return;
    if (shiftKey && key === 'R') {
      if (history.location !== pages.realEstates.path) history.push(pages.realEstates.path);
    }
    if (shiftKey && key === 'B' && selectedBuilding.id != -1) {
      if (history.location !== pages.building.path) history.push(pages.building.path);
    }
    if (building?.actions?.length > 0 && shiftKey && key.toUpperCase() === 'A') {
      if (history.location !== pages.actions.path) history.push(pages.actions.path);
    }

    if (building?.id != -1 && shiftKey && key.toUpperCase() === 'B') history.push(pages.building.path);

    if (getAccess(user, roles.epiqr_user)) {
      if (building?.macroUnits?.length > 0 && shiftKey && key === 'D') {
        if (history.location !== pages.diagnosis.path) history.push(pages.diagnosis.path);
      }
      if (building?.energyLabels?.length > 0 && shiftKey && key.toUpperCase() === 'E') {
        if (history.location !== pages.energy_check.path) history.push(pages.energy_check.path);
      }
      if (shiftKey && key === 'S') {
        if (building?.macroUnits?.length > 0)
          if (history.location !== pages.summary.path) history.push(pages.summary.path);
      }
    }
    if (building?.scenariosPageIncluded && shiftKey && key.toUpperCase() === 'C') {
      if (history.location !== pages.scenarios.path) history.push(pages.scenarios.path);
    }
  }
  const handleSpawnUpdateBuildingWorker = e => {
    if (window.worker) window.worker.terminate();
    window.worker = new Worker();
    window.worker.addEventListener('message', handleDiagnosisStructureUpdate);
    if (window.worker) window.dispatchEvent(new Event(UPDATE_BUILDING_WORKER_READY));
  };

  const addDiagnosisPageListener = () => {
    window.addEventListener(UPDATE_BUILDING_WORKER_SPAWN, handleSpawnUpdateBuildingWorker);
  };
  const removeDiagnosisPageListener = () => {
    window.removeEventListener(UPDATE_BUILDING_WORKER_SPAWN, handleSpawnUpdateBuildingWorker);
    if (window.worker) {
      window.worker.removeEventListener('message', handleDiagnosisStructureUpdate);
      window.worker.terminate();
    }
  };
  const handleDiagnosisStructureUpdate = e => {
    const { STORE_FINISHED } = UpdateBuildingStructureWorkerActions;
    const data = JSON.parse(e.data);
    if (data.ACTION_RESULT === STORE_FINISHED) {
      dispatch(buildingUpdateStructure(data.change));
      window.worker.terminate();
      window.worker = undefined;
    }
  };
  const addWindowEventListeners = () => {
    addDiagnosisPageListener();
    isDevelopmentBuild() ? window.addEventListener('keydown', goToShortcuts) : () => {};
  };
  const removeWindowEventListeners = () => {
    removeDiagnosisPageListener();
    isDevelopmentBuild() ? window.removeEventListener('keydown', goToShortcuts) : () => {};
  };
  useEffect(() => {
    addWindowEventListeners();
    return () => {
      removeWindowEventListeners();
    };
  }, []);

  const showSnackBar = (message, variant, optional = null) => {
    if (message && variant) if (appSnackbarRef.current) appSnackbarRef.current.openSnackBar(message, variant, optional);
  };
  const dismissSnackBar = () => {
    if (appSnackbarRef.current) appSnackbarRef.current.closeSnackBar();
  };
  useEffect(() => {
    if (
      Object.keys(selectedNotification)
        .filter(k => k != 'optional')
        .every(key => selectedNotification[key].length > 0)
    ) {
      showSnackBar(selectedNotification.message, selectedNotification.variant, selectedNotification?.optional);
    } else if (Object.keys(selectedNotification).every(key => selectedNotification[key].length == 0)) {
      dismissSnackBar();
    }
  }, [selectedNotification]);

  return (
    <div className={classes.root}>
      <CssBaseline />
      <Header />
      <Grid container className={classes.full}>
        <Grid item xs={12} className={classes.wh100}>
          <Page classes={classes} />
        </Grid>
      </Grid>
      <CustomSnackbar ref={appSnackbarRef} />
    </div>
  );
};

Main.displayName = 'Main';
Main.propTypes = {
  classes: PropTypes.object.isRequired,
};
Main.defaultProps = {};

export default withStyles(styles)(Main);
