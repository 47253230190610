import { blue } from '@material-ui/core/colors';
import { createTheme } from '@material-ui/core/styles';

const theme = createTheme({
  palette: {
    primary: {
      light: 'rgba(226, 255, 255, 1)',
      main: 'rgba(37, 195, 193, 1)',
      dark: 'rgba(22, 137, 173, 1)',
      contrastText: '#fff',
      contrastBackground: '#fff',
    },
    secondary: {
      light: blue[200],
      main: blue[400],
      dark: blue[600],
      contrastText: '#000',
    },
    appBackgroundColor: '#fafafa',
  },
  typography: {
    useNextVariants: true,
  },
  overrides: {},
});
theme.leftDrawerWidth = '57px';
theme.palette.extras = {
  fab: {
    danger: {
      contrastText: theme.palette.error.contrastText,
      dark: theme.palette.error.dark,
      light: theme.palette.error.main,
    },
    success: {
      dark: theme.palette.primary.dark,
      light: theme.palette.primary.main,
      contrastText: '#fff',
    },
  },
};
export default theme;
