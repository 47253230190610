const drawerWidth = 240;

const styles = theme => ({
  full: {
    width: 'calc(100% - 57px)',
    height: 'calc(100% - 64px)',
    position: 'relative',
    top: '64px',
  },
  wh100: {
    width: '100%',
    height: '100%',
  },
  root: {
    display: 'flex',
    flexGrow: 1,
    width: '100%',
    height: '100%',
  },
  titleBar: {
    paddingLeft: theme.spacing(3),
    display: 'flex',
    alignItems: 'center',
  },
  title: {
    display: 'flex',
    alignItems: 'center',
    overflow: 'hidden',
  },
  smallTitle: {
    display: 'flex',
    alignItems: 'center',
    overflow: 'hidden',
    width: '100px',
  },
  previousButton: {
    color: 'white',
  },
  avatar: {
    margin: 10,
  },
  alignRight: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    zIndex: 3,
    backgroundColor: theme.palette.primary.main,
    marginLeft: 'auto',
    marginRight: '5px',
    '& > *': {
      zIndex: 'inherit',
      backgroundColor: 'inherit',
    },
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 10,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  tabMainLabel: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  flexStickyLeftTab: {
    left: '0',
    order: '-1',
    position: 'sticky',
    zIndex: '1',
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginLeft: 12,
    marginRight: 12,
  },
  hide: {
    display: 'none',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: theme.spacing(7) + 1,
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(7) + 1,
    },
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    ...theme.mixins.toolbar,
  },
  content: {
    padding: theme.spacing(2),
    position: 'relative',
    height: '100%',
  },
  column: {
    borderRight: '1px solid rgba(0, 0, 0, 0.12)',
    display: 'flex',
    flexDirection: 'column',
  },
  center: {
    width: '100%',
    marginLeft: 'auto',
    marginRight: 'auto',
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    textAlign: 'center',
  },
  centerProgressBar: {
    width:'30%',
    height:'30%',
  },
  centerFlexWH100: {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  filter: {
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    width: '100%',
  },
  scrollable: {
    height: `calc(100vh - 64px + 15px)`,
    overflow: 'scroll',
  },
  scrollableWithSubHeader: {
    height: `calc(100vh - 64px - 72px + 15px)`,
    overflow: 'scroll',
  },
  icon: {
    display: 'inline-flex',
    verticalAlign: 'text-bottom',
    boxSizing: 'inherit',
    textAlign: 'center',
    alignItems: 'center',
  },
  rotation: {
    transform: 'rotate(-90deg)',
  },
  // speedDial: {
  //   position: "absolute",
  //   marginTop: "-20px",
  //   marginLeft: "10px",
  // },
  speedDialAction: {
    marginTop: '0px',
    marginBottom: theme.spacing(1),
  },
  noUpperCase: {
    textTransform: 'none',
  },
  extendedIcon: {
    marginRight: theme.spacing(1),
  },
  languageButton: {
    margin: theme.spacing(0, 3),
  },
  tab: {
    height: '72px',
    minWidth: '130px',
    textTransform: 'none',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    flex: 1,
    position: 'relative',
    '&.Mui-selected': {
      opacity: '0.7',
    },
  },
  diagnosisTabs: {
    minWidth: '230px !important',
  },
  speedDialEditContainer: {
    height: '72px',
    position: 'relative',
  },
  speedDialEdit: {
    top: 'calc(72px / 2 - 72px / 4)',
    position: 'absolute',
  },
  tabEditMode: {
    top: '-10px',
    color: 'white',
    right: '-5px',
    width: '25px !important',
    cursor: 'pointer',
    zIndex: '2000',
    position: 'absolute',
    borderRadius: '50%',
    maxHeight: '0 !important',
    minHeight: '25px !important',
    backgroundColor: theme.palette.extras.fab.danger.light,
    '&:hover': {
      backgroundColor: theme.palette.extras.fab.danger.dark,
    },
  },
  relative: {
    position: 'relative',
  },
  tabBorderRight: {
    borderRight: '1px solid rgba(0, 0, 0, 0.12)',
  },
  tabBorderLeft: {
    borderLeft: '1px solid rgba(0, 0, 0, 0.12)',
  },
  speedDialActionEdit: {
    marginTop: '0px',
    marginBottom: theme.spacing(1),
    color: 'white',
    backgroundColor: theme.palette.extras.fab.success.light,
    '&:hover': {
      backgroundColor: theme.palette.extras.fab.success.dark,
    },
  },
  speedDialActionDanger: {
    backgroundColor: theme.palette.extras.fab.danger.light,
    '&:hover': {
      backgroundColor: theme.palette.extras.fab.danger.dark,
    },
  },
  normalPointer: {
    cursor: 'default',
  },
  flexColumn: {
    display: 'flex',
    flexDirection: 'column',
  },
  popUpForm: {
    backgroundColor: '#fff',
    gap: '0.9375rem',
  },
  popUpCenter: {
    margin: ' 10px auto 0px auto',
  },
  topContainer: {
    position: 'relative',
  },
  backgroundColorInherit: {
    '&:focus': {
      backgroundColor: 'inherit',
    },
  },
  popUpBox_BackShadow: {
    width: '100%',
    height: '100%',
    zIndex: '1300',
    position: 'absolute',
    background: 'transparent',
    top: 0,
    right: 0,
    left: 0,
    bottom: 0,
  },
  popUpBox: {
    top: '80px',
    right: '100px',
    display: 'flex',
    padding: '20px',
    zIndex: '1000',
    position: 'absolute',
    boxShadow: '0 0 0 10px rgba(0, 0, 0, 0.3)',
    minWidth: '250px',
  },
  popUpBox_hide: {
    display: 'none',
  },
  elementsContainer: {
    marginBottom: '20px',
  },
  fabSmall: {
    margin: '0 5px !important',
  },
  fabCancel: {
    backgroundColor: theme.palette.extras.fab.danger.light,
    '&:hover': {
      backgroundColor: theme.palette.extras.fab.danger.dark,
    },
  },
  marginHorizontal5: {
    margin: '0 25px',
  },
    fabCheck: {
    backgroundColor: theme.palette.extras.fab.success.light,
    '&:hover': {
      backgroundColor: theme.palette.extras.fab.success.dark,
    },
    '& .Mui-disabled': {
      position: 'relative',
      filter: 'opacity(0.4)',
      '&:hover': {
        cursor: 'not-allowed',
      },
    },
  },
});

export default styles;
