const styles = theme => ({
  box: {
    height: '100%',
    padding: '15px',
    border: '0.0625rem solid rgba(1,1,1,0.7) ',
    borderRadius: '0.625rem',
    [theme.breakpoints.only('xs')]: {
      minHeight: '18.75rem',
      maxWidth: 'auto',
    },
        [theme.breakpoints.up('sm')]: {
            '&  .MuiTab-root': {
                minWidth: 'calc( 9.375rem - 1.5625rem)',
                maxWidth: 'calc( 9.375rem - 0.9375rem)',
            },
        },
    [theme.breakpoints.down('sm')]: {
      '&  .MuiTab-root': {
        minWidth: 'calc( 5.625rem - 1.5625rem)',
        maxWidth: 'calc( 6.25rem - 0.9375rem)',
      },
      minHeight: '17.1875rem',
      maxWidth: '18.75rem',
    },
  },
  inputContainer: {
    height: 'calc(100% - 48px)',
    justifyContent: 'space-evenly',
    '& > * ': {
      flexBasis: 0,
    },
  },
});
export default styles;
