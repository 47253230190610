export const ruleRunner = (field, name, ...validations) => {
  return (value, index) => {
    for (let v of validations) {
      let errorMessageFunc = v(value);
      if (errorMessageFunc) {
        if (index == -1) {
          return {
            [field]:
              typeof errorMessageFunc === 'function'
                ? errorMessageFunc(name)
                : errorMessageFunc,
          };
        } else {
          return {
            [field + '.' + index]:
              typeof errorMessageFunc === 'function'
                ? errorMessageFunc(name)
                : errorMessageFunc,
          };
        }
      }
    }
    return {};
  };
};

export const run = (field, value, runners, index = -1) => {
  return runners[field](value, index);
};
