export const SET_USER = 'SET_USER';
export const LOGOUT = 'LOGOUT';
export const SET_REALESTATE = 'SET_REALESTATE';


export const setUser = (user) => ({
  type: SET_USER,
  user,
})

export const logout = (user = null) => ({
  type: LOGOUT,
  user,
})

export const setRealEstate = (realEstate) => ({
  type: SET_REALESTATE,
  realEstate,
})
