import { CHANGE_LOADING, CHANGE_PAGE_ITEM_LOADING } from '../Actions/loading';
import createReducer from '../Reducers/general';
const initialState = {
  isLoading: false,
  location: null,
  pageItemLoading: false,
};

const loading = (state = initialState, action) => {
  switch (action.type) {
    case CHANGE_PAGE_ITEM_LOADING:
      return {
        ...state,
        pageItemLoading: action.payload.pageItemLoading,
      };
    case CHANGE_LOADING:
      return {
        isLoading: action?.payload?.isLoading || !state.isLoading,
        location: action?.payload?.location,
        pageItemLoading: action.payload.pageItemLoading,
      };
    default:
      return state;
  }
};

export const loadingReducer = createReducer(initialState, {
  CHANGE_LOADING: loading,
});
