import _, { cloneDeep } from 'lodash';
import {
  BUILDING_CHANGE,
  BUILDING_CLEAR,
  BUILDING_UPDATE,
  BUILDING_UPDATE_STRUCTURE,
  DELETE_ACTIONS,
  DELETE_CONSUMPTION_PRODUCTION,
  DELETE_SCENARIO,
  INSERT_ACTIONS,
  INSERT_SCENARIO,
  UPDATE_ACTION,
  UPDATE_CONSUMPTION_PRODUCTION,
  UPDATE_MACRO_UNIT_ACTIVE_STATE,
  UPDATE_SCENARIOS,
  UPDATE_SPECIFIC_ENERGY_LABEL,
} from '../Actions/building.js';
import { addStructureToScenarios, RecalculateBuildingCosts, UpdateReferenceScenario } from '../Functions/Building.js';
import { updateTypeCosts } from '../Functions/Types.js';
import createReducer from './general.js';

const initialState = {
  id: -1,
  name: '',
  description: '',
  address: {},
  consumption_production: [],
  consumption: {},
  heat_balance: {},
  macroUnits: undefined,
  scenarios: undefined,
  energyLabels: {
    macroUnits: [],
  },
};

const building = (state = initialState, action) => {
  let newBuilding = {};
  switch (action?.type) {
    case BUILDING_CHANGE:
      if (action.payload.building == null) return initialState;
      return {
        ...action.payload.building,
      };
    case BUILDING_UPDATE:
      let buildingWithStructure;
      if (Object.keys(action.payload.building).includes('macroUnits')) {
        action.payload.building.macroUnits = action.payload.building.macroUnits.map(m => {
          m.units = m.units.map(u => {
            if (u.types)
              u.types = u.types.map(t => {
                return updateTypeCosts(t);
              });
            return u;
          });
          return m;
        });
        buildingWithStructure = addStructureToScenarios(action.payload.building);
      }
      let newModifiedState;
      let building = buildingWithStructure ? buildingWithStructure : action.payload.building;
      if (action.payload.shouldRecalculate)
        if (building?.macroUnits?.length > 0) {
          building = RecalculateBuildingCosts(building, action.payload.changedCoefDims);
        } else if (state?.macroUnits?.length > 0) {
          newModifiedState = RecalculateBuildingCosts(cloneDeep(state), action.payload.changedCoefDims);
        }

      if (newModifiedState) {
        newBuilding = _.merge(building, newModifiedState);
      } else {
        let clonedState = cloneDeep(state);

        newBuilding = _.merge(clonedState, building);
      }

      UpdateReferenceScenario(newBuilding);
      return { ...newBuilding };
    case BUILDING_CLEAR:
      return initialState;
    case BUILDING_UPDATE_STRUCTURE:
      return {
        ...state,
        ...action.payload.change,
      };
    case INSERT_ACTIONS: {
      let actions = cloneDeep(state.actions);
      let newActions = action.payload.actions.flat();
      let alreadyExistingActions = actions.filter(
        _action => newActions.findIndex(currentNewAction => currentNewAction.id === _action.id) > -1
      );
      if (alreadyExistingActions.length > 0) {
        alreadyExistingActions.forEach(existingAction => {
          const existingActionIdx = actions.findIndex(_action => _action.id === existingAction.id);
          if (existingActionIdx > -1) {
            let newAction = newActions.find(_action => _action.id === existingAction.id);
            if (newAction) actions[existingActionIdx] = newAction;
          }
        });
      } else {
        actions.push(...newActions);
      }
      return {
        ...state,
        actions,
      };
    }
    case UPDATE_ACTION: {
      let actions = cloneDeep(state.actions);
      let newActions = action.payload.actions.flat();
      let alreadyExistingActions = actions.filter(
        _action => newActions.findIndex(currentNewAction => currentNewAction.id === _action.id) > -1
      );
      if (alreadyExistingActions.length > 0) {
        alreadyExistingActions.forEach(existingAction => {
          const existingActionIdx = actions.findIndex(_action => _action.id === existingAction.id);
          if (existingActionIdx > -1) {
            let newAction = newActions.find(_action => _action.id === existingAction.id);
            if (newAction) actions[existingActionIdx] = newAction;
          }
        });
      } else {
        actions.push(...newActions);
      }
      return {
        ...state,
        actions,
      };
    }

    case DELETE_ACTIONS: {
      newBuilding = cloneDeep(state);
      let actionsArr = cloneDeep(newBuilding.actions);
      let removeIds = action.payload?.actionIds.flat();
      removeIds.forEach(actionId => {
        let indexToModify = actionsArr.findIndex(item => item.id === actionId);
        if (indexToModify != -1) actionsArr.splice(indexToModify, 1);
      });
      newBuilding.actions = actionsArr;
      return { ...newBuilding };
    }
    case INSERT_SCENARIO: {
      let scenariosArr = cloneDeep(state.scenarios);
      let newScenario = action.payload.scenario;
      scenariosArr.push(newScenario);
      if (newScenario.isReference)
        scenariosArr = scenariosArr.map(_scenario => {
          if (_scenario.isReference && _scenario.id !== newScenario.id) _scenario.isReference = false;
          return _scenario;
        });
      return {
        ...state,
        scenarios: scenariosArr,
      };
    }
    case UPDATE_SCENARIOS: {
      newBuilding = cloneDeep(state);
      let updateScenarioList = action.payload?.scenarios ?? [];
      let scenariosArr = cloneDeep(newBuilding.scenarios);

      updateScenarioList.forEach(currentUpdateScenario => {
        let indexToModify = scenariosArr.findIndex(item => item.id === currentUpdateScenario.id);
        if (indexToModify != -1) scenariosArr[indexToModify] = currentUpdateScenario;
        if (currentUpdateScenario.isReference)
          scenariosArr = scenariosArr.map(_scenario => {
            if (_scenario.isReference && _scenario.id !== currentUpdateScenario.id) _scenario.isReference = false;
            return _scenario;
          });
      });
      newBuilding.scenarios = scenariosArr;
      return { ...newBuilding };
    }
    case DELETE_SCENARIO: {
      newBuilding = cloneDeep(state);
      let scenarioId = action.payload?.scenarioId ?? -1;
      let scenariosArr = cloneDeep(newBuilding.scenarios);
      let indexToModify = scenariosArr.findIndex(item => item.id === scenarioId);
      if (indexToModify != -1) scenariosArr.splice(indexToModify, 1);
      newBuilding.scenarios = scenariosArr;
      return { ...newBuilding };
    }
    case UPDATE_CONSUMPTION_PRODUCTION: {
      let newConsumptionProduction = cloneDeep(state.consumption_production);
      let { consumption_production: newEnergy } = action.payload;
      let indexToModify = newConsumptionProduction.findIndex(energy => energy.id === newEnergy.id);
      if (indexToModify != -1) {
        newConsumptionProduction[indexToModify] = newEnergy;
      } else {
        newConsumptionProduction.push(newEnergy);
      }
      return {
        ...state,
        consumption_production: newConsumptionProduction,
      };
    }
    case DELETE_CONSUMPTION_PRODUCTION: {
      let newConsumptionProduction = cloneDeep(state.consumption_production);
      let { deleteId } = action.payload;
      let indexToModify = newConsumptionProduction.findIndex(energy => energy.id === deleteId);
      if (indexToModify != -1) newConsumptionProduction.splice(indexToModify, 1);
      return {
        ...state,
        consumption_production: newConsumptionProduction,
      };
    }
    case UPDATE_SPECIFIC_ENERGY_LABEL: {
      let { macroUnitIdx, change } = action.payload;
      let newState = cloneDeep(state);
      if (
        macroUnitIdx == -1 ||
        newState.energyLabels.macroUnits == undefined ||
        macroUnitIdx > newState.energyLabels.macroUnits.length - 1 ||
        newState.energyLabels.macroUnits[macroUnitIdx] === undefined
      )
        return state;
      newState.energyLabels.macroUnits[macroUnitIdx].selectedEnergyLabel = change.energyLabel;
      newState.energyLabels.macroUnits[macroUnitIdx].energyLabelComment = change.energyLabelComment;
      return { ...newState };
    }
    case UPDATE_MACRO_UNIT_ACTIVE_STATE:
      let { macroUnitId, active } = action.payload;
      let newState = cloneDeep(state);
      if (macroUnitId == -1) return state;
      const muIndex = newState.macroUnits.findIndex(mu => mu.id === macroUnitId);
      if (muIndex == -1) return state;
      newState.macroUnits[muIndex].active = active;
      return { ...newState };
    default:
      return state;
  }
};

export const buildingReducer = createReducer(initialState, {
  BUILDING_CHANGE: building,
  INSERT_ACTIONS: building,
  INSERT_SCENARIO: building,
  BUILDING_UPDATE: building,
  BUILDING_UPDATE_STRUCTURE: building,
  UPDATE_ACTION: building,
  UPDATE_SCENARIOS: building,
  UPDATE_SPECIFIC_ENERGY_LABEL: building,
  UPDATE_CONSUMPTION_PRODUCTION: building,
  UPDATE_MACRO_UNIT_ACTIVE_STATE: building,
  BUILDING_CLEAR: building,
  DELETE_ACTIONS: building,
  DELETE_CONSUMPTION_PRODUCTION: building,
  DELETE_SCENARIO: building,
});
