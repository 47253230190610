import PropTypes from 'prop-types';
import React from 'react';
import { Redirect, Route } from 'react-router-dom';

function PrivateRoute({ component: Component, hidden, user, ...rest }) {
  return (
    <Route
      {...rest}
      render={props => (user ? <Component {...props} /> : <Redirect to='/' />)}
    />
  );
}

PrivateRoute.displayName = 'PrivateRoute';
PrivateRoute.propTypes = {
  user: PropTypes.object,
};

PrivateRoute.defaultProps = {
  user: null,
};

export default PrivateRoute;
