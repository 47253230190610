import { TITLE_CHANGE } from '../Actions/title.js';
import createReducer from './general.js';

const initialState = { main: 'EPIQRweb', subtitle: '', picture: '' };

const title = (state, action) => {
  switch (action.type) {
    case TITLE_CHANGE:
      return { ...state, ...action.title };

    default:
      return initial_state;
  }
};

export const titleReducer = createReducer(initialState, {
  TITLE_CHANGE: title,
});
