import { Grid, Typography, withStyles } from '@material-ui/core';
import React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
const styles = theme => ({
  description: {
    fontFamily: 'Avenir Next',
    fontWeight: 'normal',
    textAlign: 'justify',
    textJustify: 'inter-word',
  },
  gap: {
    gap: '0.3125rem',
  },
  gap30: {
    gap: '1.875rem',
  },
});
const PlatformDescription = ({ classes, ...props }) => {
  return (
    <Grid
      container
      item
      direction='column'
      xs={10}
      justifyContent='space-between'
      className={classes.gap30}
      wrap='nowrap'>
      <Grid container item direction='column' xs={12} justifyContent='space-between' className={classes.gap}>
        <Typography variant='h5' className={classes.description}>
          <FormattedMessage id='app.description.RAT.title' />
        </Typography>
        <Typography variant='body1' className={classes.description}>
          <FormattedMessage id='app.description.RAT' />
        </Typography>
      </Grid>
      <Grid container item direction='column' xs={12} justifyContent='space-between' className={classes.gap}>
        <Typography variant='h5' className={classes.description}>
          <FormattedMessage id='app.description.EPIQRweb.title' />
        </Typography>
        <Typography variant='body1' className={classes.description}>
          <FormattedMessage id='app.description.EPIQRweb' />
        </Typography>
      </Grid>
    </Grid>
  );
};

export default injectIntl(withStyles(styles)(PlatformDescription));
