import {
  CHANGE_SELECTED_REPORT,
  DELETE_REPORT,
  INSERT_REPORT,
  UPDATE_REPORT,
  UPDATE_REPORTS_LIST,
} from "../Actions/reports";
const initialState = {
  list: [],
  selected: -1,
};

export const reportsReducer = (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_REPORTS_LIST:
      return {
        ...state,
        list: [...action.reportsList],
        selected: action.reportsList[0].id,
      };
    case CHANGE_SELECTED_REPORT:
      return { ...state, selected: action.selected };
    case UPDATE_REPORT:
      return {
        ...state,
        list: state.list.map((report, index) => {
          if (index == action.index || report?.id == state?.selected)
            return { ...report, ...action.report };
          return report;
        }),
      };
    case INSERT_REPORT:
      return {
        ...state,
        selected: action.selected,
        list: [...state.list, action.report],
      };
    case DELETE_REPORT:
      let list = state.list.filter((report, index) =>
        action.index == -1
          ? report?.id != state?.selected
          : index != action?.index
      );
      return {
        ...state,
        selected: list?.length > 0 ? list[0]?.id : -1,
        list: [...list],
      };
    default:
      return state;
  }
};
