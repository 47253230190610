import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import AppBar from '@material-ui/core/AppBar';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import { useTheme, withStyles } from '@material-ui/core/styles';
import Toolbar from '@material-ui/core/Toolbar';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { logout, setUser } from '../../Actions/user.js';
import styles from '../../Themes/menu.js';
import SignedIn from '../HomePage/SignedIn.js';
import Tooltip from '../Misc/CustomTooltip.js';
import ErrorBoundary from '../Misc/ErrorBoundary.js';
import CustomSnackbar from '../Misc/Snackbar.js';
import LeftMenu from './LeftMenu.js';
import SelectLanguage from './SelectLanguage.js';
import Title from './Title.js';

const Header = ({ history, classes, title }) => {
  const theme = useTheme();
  const snackbarRef = React.useRef(null);

  const [open, setOpen] = useState(false);

  const _showSnackBar = (message, variant) => {
    snackbarRef.current.openSnackBar(message, variant);
  };

  return (
    <ErrorBoundary>
      <AppBar position='fixed' className={classes.appBar}>
        <Toolbar disableGutters={true} className={classes.toolbar}>
            <Grid container>
          <Grid item >
            <Tooltip title={'app.goback'}>
              <IconButton
                color='inherit'
                className={classes.menuButton}
                onClick={() => history.goBack()}>
                <FontAwesomeIcon icon={faArrowLeft} />
              </IconButton>
            </Tooltip>
          </Grid>
            <Grid item xs={8} className={classes.titleBar}>
              <Title title={title} classes={classes} />
            </Grid>
            <Grid
              container
              item
              xs={3}
              wrap='nowrap'
              className={classes.alignRight}>
              <SelectLanguage classes={classes} />
              {/* <Logout classes={classes} onClick={onLogout}/> */}
              <SignedIn
                showSnackBar={_showSnackBar}
                color={theme.palette.primary.contrastBackground}
                typographyComponent='h6'
                iconSize='sm'
              />
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
      <LeftMenu classes={classes} />
      <CustomSnackbar ref={snackbarRef} />
    </ErrorBoundary>
  );
};

Header.displayName = 'Header';
Header.propTypes = {
  history: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
  title: PropTypes.shape({
    main: PropTypes.string.isRequired,
    subtitle: PropTypes.string.isRequired,
    picture: PropTypes.string.isRequired,
  }).isRequired,
  user: PropTypes.object,
  setUser: PropTypes.func.isRequired,
  logout: PropTypes.func.isRequired,
};
Header.defaultProps = {
  user: null,
};

const mapStateToProps = state => ({
  user: state.user,
  title: state.title,
});

const mapDispatchToProps = dispatch => ({
  setUser: user => dispatch(setUser(user)),
  logout: () => dispatch(logout()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(withStyles(styles)(Header)));
