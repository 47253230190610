import { STRUCTURE_TYPE__MACRO, STRUCTURE_TYPE__TYPE, STRUCTURE_TYPE__UNIT } from '@/Actions/building.js';
import { filterDiagnosisGroups, updateTypeCosts } from '@/Functions/Types';
import { cloneDeep } from 'lodash';
import { baseApiUrl, baseApiUrlWorker } from '.';
import { checkLanguage } from './Language';
import { improvementsSTUV } from './Types';
import { RoundNumber } from './Utility';

export const FETCH_BUILDING_DETAILS_TYPE = {
  STRUCTURE: 'STRUCTURE',
  ACTIONS_AND_SCENARIOS: 'ACTIONS_AND_SCENARIOS',
  ACTIONS: 'ACTIONS',
  SCENARIOS: 'SCENARIOS',
};

export function getAddressToString(address) {
  let addr = null;
  address.address1 ? (addr = address.address1) : null;

  address.address2 ? (addr ? (addr += ', ' + address.address2) : (addr = address.address2)) : null;

  address.address3 ? (addr ? (addr += ', ' + address.address3) : (addr = address.address3)) : null;

  address.zip
    ? address.town
      ? addr
        ? (addr += ', ' + address.zip + ' ' + address.town)
        : (addr = address.zip + ' ' + address.town)
      : addr
      ? (addr += ', ' + address.zip)
      : (addr = address.zip)
    : address.town
    ? addr
      ? (addr += ', ' + address.town)
      : (addr = address.town)
    : null;

  address.country ? (addr ? (addr += ', ' + address.country) : (addr = address.country)) : null;

  return addr;
}

export const addStructureToScenariosForReports = (macroUnits, scenarios) => {
  scenarios.forEach(scenario => {
    let currMacros = [...JSON.parse(JSON.stringify(macroUnits))];
    let structure = currMacros
      .map(macro => {
        macro.units = macro.units
          .map(unit => {
            unit.types = unit.types
              .map(type => {
                type.interventions = [...type.interventions.filter(iv => iv.scenario.id == scenario.id)];

                return type.interventions.length > 0 ? type : null;
              })
              .filter(t => t != null);

            return unit.types.length > 0 ? unit : null;
          })
          .filter(unit => unit != null);

        return macro.units.length > 0 ? macro : null;
      })
      .filter(m => m != null);
    scenario.structure = structure;
  });
  return scenarios;
};

export const isBuildingEmpty = (building = {}) => {
  if (building?.id == -1) return true;

  let testObject = { ...building };
  delete testObject.reference_area;
  delete testObject.financial_reference_year;
  delete testObject.refurbishment_year;
  let allPropertyValues = Object.values(testObject);

  return (
    allPropertyValues.filter(value => {
      if (value == null || value === 'undefined') return false;
      if (Array.isArray(value)) if (value.length == 0) return false;
      if (typeof value === 'string' && value.length == 0) return false;
      if (typeof value === 'number' && isNaN(value)) return false;
      if (_.isEmpty(value)) return false;
      return true;
    }).length == 0
  );
};

export const addStructureToScenarios = (building, structureTree = undefined) => {
  let clonedBuilding = cloneDeep(building);
  if (building.scenarios && building.macroUnits) {
    let scenarios = clonedBuilding?.scenarios?.map(scenario => {
      if (!Object?.keys(scenario)?.includes('structure')) {
        scenario.structure = {};
      }

      let currentScenarioMacros = scenario.structure.macros;
      if (structureTree && currentScenarioMacros) {
        let { macroUnitIdx = -1, unitIdx = -1, typeIdx = -1 } = structureTree;
        let oldType = currentScenarioMacros?.[macroUnitIdx]?.units?.[unitIdx]?.types?.[typeIdx];
        let newType = clonedBuilding?.macroUnits?.[macroUnitIdx]?.units?.[unitIdx]?.types?.[typeIdx];
        if (currentScenarioMacros[macroUnitIdx].units[unitIdx]?.types?.length == 0) return scenario;
        currentScenarioMacros[macroUnitIdx].units[unitIdx].types.map((type, index) => {
          if (index == typeIdx) {
            let oldTotal = 0,
              newTotal = 0,
              oldTotalNoD = 0,
              oldTotalNoImprovements = 0,
              newTotalNoD = 0,
              newTotalNoImprovements = 0;
            type?.scenarios
              ?.find(_scenario => _scenario.id == scenario.id)
              ?.interventions?.forEach(iv => {
                let iv_cost = RoundNumber(iv.cost ?? 0);
                if (iv.model.code.slice(-1) != 'd') oldTotalNoD += iv_cost;
                if (!improvementsSTUV.includes(iv.model.code.slice(-1))) oldTotalNoImprovements += iv_cost;
                oldTotal += iv_cost;
              });

            let newInterventions =
              newType?.scenarios
                ?.find(_scenario => _scenario.id == scenario.id)
                ?.interventions?.map(iv => {
                  let iv_cost = RoundNumber(iv.cost ?? 0);
                  if (iv.model.code.slice(-1) != 'd') newTotalNoD += iv_cost;
                  if (!improvementsSTUV.includes(iv.model.code.slice(-1))) newTotalNoImprovements += iv_cost;
                  newTotal += iv_cost;
                  iv.cost = iv_cost;
                  return iv;
                }) ?? [];

            type.interventions = newInterventions;
            currentScenarioMacros[macroUnitIdx].costs.total =
              currentScenarioMacros[macroUnitIdx].costs.total - oldTotal + newTotal;
            currentScenarioMacros[macroUnitIdx].costs.totalNoD =
              currentScenarioMacros[macroUnitIdx].costs.totalNoD - oldTotalNoD + newTotalNoD;
            currentScenarioMacros[macroUnitIdx].costs.totalNoImprovements =
              currentScenarioMacros[macroUnitIdx].costs.totalNoImprovements -
              oldTotalNoImprovements +
              newTotalNoImprovements;
            return type;
          }
          return type;
        });
        scenario.investment_cost = currentScenarioMacros.reduce((sum, current) => sum + current.costs.total, 0);
        return scenario;
      } else {
        scenario.structure.macros =
          cloneDeep(clonedBuilding?.macroUnits)
            ?.map(m => {
              if (!Object.keys(m).includes('costs')) {
                m.costs = {};
              }
              let totalNoD = 0;
              let totalNoImprovements = 0;
              let total = 0;
              let newUnits =
                m?.units
                  ?.map(u => {
                    let newTypes =
                      u?.types
                        ?.map(t => {
                          let newInterventions =
                            t?.scenarios
                              ?.find(_scenario => _scenario.id == scenario.id)
                              ?.interventions?.map(iv => {
                                let iv_cost = RoundNumber(iv.cost ?? 0);
                                if (iv.model.code.slice(-1) != 'd') totalNoD += iv_cost;
                                if (!improvementsSTUV.includes(iv.model.code.slice(-1))) totalNoImprovements += iv_cost;
                                total += iv_cost;
                                iv.cost = iv_cost;
                                return iv;
                              }) ?? [];

                          t.interventions = newInterventions;
                          return t;
                        })
                        ?.filter(it => it != null) || [];

                    if (newTypes.length == 0) return null;
                    u.types = newTypes;
                    return u;
                  })
                  ?.filter(it => it != null) || [];
              if (newUnits.length == 0) return null;
              m.costs.total = total;
              m.costs.totalNoD = totalNoD;
              m.costs.totalNoImprovements = totalNoImprovements;
              m.units = newUnits;
              return m;
            })
            ?.filter(it => it != null) || [];
        if (clonedBuilding?.macroUnits?.some(mu => mu?.units?.some(u => u?.types?.length > 0))) {
          let investment_cost = scenario.structure.macros.reduce(
            (total, current) => (total += Number(current?.costs?.total ?? 0)),
            0
          );
          scenario.investment_cost = investment_cost;
        }
        return scenario;
      }
    });
    return { ...clonedBuilding, scenarios };
  }
  return clonedBuilding;
};
export const UpdateBuildingStructure = (state, actions = []) => {
  try {
    let currentBuilding = _.cloneDeep(state);
    let otherMacroUnit = state.macroUnits.find(mu => mu.code === 'UT-1');
    if (actions.length == 0) return false;

    let macroUnits = _.cloneDeep(filterDiagnosisGroups(state));
    actions.forEach(action => {
      let { change, structureTree, structureType } = action;

      let macroUnitIdx = structureTree.macroUnitIdx ?? -1;
      let unitIdx = structureTree.unitIdx ?? -1;
      let typeIdx = structureTree.typeIdx ?? -1;

      let macroUnit = macroUnitIdx != -1 ? macroUnits[macroUnitIdx] : null;
      let units = macroUnit ? [...macroUnit?.units] : undefined;
      let types = unitIdx != -1 ? [...units?.[unitIdx]?.types] : null;

      switch (structureType) {
        case STRUCTURE_TYPE__MACRO:
          if (macroUnitIdx != -1) macroUnits[macroUnitIdx] = change;
          break;
        case STRUCTURE_TYPE__UNIT:
          if (macroUnitIdx != -1 && unitIdx != -1) macroUnits[macroUnitIdx].units[unitIdx] = change;
          break;
        case STRUCTURE_TYPE__TYPE:
          if (macroUnitIdx != -1 && unitIdx != -1 && typeIdx != -1) {
            macroUnits[macroUnitIdx].units[unitIdx].types[typeIdx].updatedAt = Date.now();
            macroUnits[macroUnitIdx].units[unitIdx].types[typeIdx] = change;
          }
          break;
        default:
          return state;
      }
    });
    let newBuilding = { ...currentBuilding, macroUnits };
    let newBuildingWithScenarioStructure = addStructureToScenarios(newBuilding);
    currentBuilding = UpdateReferenceScenario(newBuildingWithScenarioStructure);
    return {
      ..._.cloneDeep(currentBuilding),
      macroUnits: [...currentBuilding.macroUnits, otherMacroUnit],
      previous_state: { ...state },
    };
  } catch (e) {
    console.error(e);
  }
};

export const RecalculateBuildingCosts = (state, changedCoefDims) => {
  let currState = _.cloneDeep(state);
  //Find Cost Dimensional Coefficients
  const CostCoefficientsArr = ['KKK01', 'KKK02', 'KKK03', 'KKK05'];
  const coefDims = currState.coefDims.map(coefDim => {
    if (coefDim.id in changedCoefDims) {
      coefDim.value = changedCoefDims[coefDim.id];
    }
    return coefDim;
  });
  const costCoefficients = coefDims.filter(coef => CostCoefficientsArr.some(code => code.includes(coef.code)));

  // Calculate current complexCoefficient
  const complexCoef = costCoefficients.reduce(
    (complexCoef, current) => (complexCoef * Number(current.value ?? 0)) / 100,
    1
  );

  let macroUnits = currState.macroUnits;

  let newMacroUnits = macroUnits.map(mu => {
    mu.units = mu.units.map(u => {
      if (u?.types?.length > 0)
        u.types = u.types.map(type => {
          type.model.interventionModels = type.model.interventionModels.map(im => {
            let modelQuantities = type.measuredQuantities.filter(mq => mq.code.includes(im.code));
            let calculation = modelQuantities.reduce(
              (cost, current) =>
                cost +
                complexCoef *
                  Number(coefDims.find(it => it.id == current.coefDimId).value ?? 0) *
                  current.evaluationsHelper.costWork.multiplier,
              0
            );
            im.cost = calculation;
            return im;
          });

          type.measuredQuantities = type.measuredQuantities.map(mq => {
            mq.newUnitaryCost = mq.evaluationsHelper.unitaryCost.multiplier * complexCoef;
            return mq;
          });
          let newType = _.cloneDeep(type);
          type.scenarios = updateTypeCosts(newType).scenarios;
          return type;
        });
      return u;
    });
    return mu;
  });
  currState = { ...currState, macroUnits: [...newMacroUnits] };
  const newState = addStructureToScenarios(currState);
  return newState;
};

export const UpdateBuildingStructureSpecific = (state, action) => {
  let currentBuilding = _.cloneDeep(state);
  let { change, structureTree, structureType } = action.payload;
  let { macroUnitId = -1, unitId = -1, typeId = -1 } = structureTree || {};
  let { macroUnitIdx = -1, unitIdx = -1, typeIdx = -1 } = structureTree || {};
  let macroUnits = _.cloneDeep(state?.macroUnits);

  let macroUnit = macroUnitIdx != -1 ? { ...macroUnits?.[macroUnitIdx] } : null;
  let units = macroUnit && [...macroUnit?.units];
  let types = unitIdx != -1 ? [...units?.[unitIdx]?.types] : null;
  let updates;
  switch (structureType) {
    case STRUCTURE_TYPE__MACRO:
      macroUnits[macroUnitIdx] = change;
      break;
    case STRUCTURE_TYPE__UNIT:
      macroUnits[macroUnitIdx].units[unitIdx] = change;
      break;
    case STRUCTURE_TYPE__TYPE:
      updates = change;
      updates.updatedAt = Date.now();
      updates.structureTree = structureTree;
      break;
    default:
      return state;
  }

  let newBuilding = { ...currentBuilding, macroUnits };
  let newBuildingWithScenarioStructure = addStructureToScenarios(newBuilding);
  let finalBuilding = UpdateReferenceScenario(newBuildingWithScenarioStructure);
  return {
    ..._.cloneDeep(finalBuilding),
    previous_state: { ...state },
  };
};

export const UpdateReferenceScenario = newBuilding => {
  let newReferenceScenario = newBuilding?.scenarios?.find(scenario => scenario.isReference);
  if (newReferenceScenario) newBuilding.reference_scenario = _.cloneDeep(newReferenceScenario);

  return newBuilding;
};

export const UpdateBuildingCall = async updatedBuilding => {
  try {
    let call = await fetch(`${baseApiUrl}/building/${updatedBuilding.id}`, {
      method: 'PUT',
      headers: {
        Accept: 'json',
        'Content-Type': 'json',
      },
      body: JSON.stringify(updatedBuilding),
      keepalive: true,
    }).then(res => res.json());
    return await call;
  } catch (error) {
    return { ERROR: error.message };
  }
};

export const UploadBuildingPictureCall = async (signal, buildingId, file) => {
  const fd = new FormData();
  fd.append('file', file);

  try {
    let dataJson = await fetch(`${baseApiUrl}/upload/building-picture/${buildingId}`, {
      method: 'POST',
      body: fd,
      signal,
    });
    let response = await dataJson.json();
    return response;
  } catch (error) {
    return { ERROR: error };
  }
};

export const FetchBuildingStructureTypes = async ({ building, intl, signal, fromWorker = false }) => {
  let response = await fetch(
    `${fromWorker ? baseApiUrlWorker : baseApiUrl}/building/${building.id}/structure/${checkLanguage(
      intl.locale
    )}/types`,
    {
      signal,
      method: 'GET',
    }
  );
  response = await response.json().catch(err => JSON.stringify(err.message));
  return response;
};
export const FetchBuildingStructureForActions = async ({ building, intl, signal, fromWorker = false }) => {
  let response = await fetch(
    `${fromWorker ? baseApiUrlWorker : baseApiUrl}/building/${building.id}/structure/${intl.locale}`,
    {
      method: 'GET',
      signal,
    }
  );
  response = await response.json().catch(err => JSON.stringify(err.message));
  return response;
};

export const FetchBuildingActionsAndScenarios = async ({ building, intl, signal, fromWorker = false, ...data }) => {
  let structurePromise = data.isEpiqrUser
    ? null
    : FetchBuildingStructureForActions({ building, intl, signal, fromWorker });
  let actionPromise = FetchBuildingActions({ building, intl, signal, fromWorker });
  let scenarioPromise = FetchBuildingScenarios({ building, intl, signal, fromWorker });
  let responses = await Promise.allSettled([structurePromise, actionPromise, scenarioPromise].filter(Boolean)).catch(
    err => err
  );
  return responses;
};
export const FetchBuildingActions = async ({ building, intl, signal, fromWorker = false }) => {
  return await fetch(`${fromWorker ? baseApiUrlWorker : baseApiUrl}/building/${building.id}/actionWithDetails`, {
    signal,
    method: 'GET',
  }).then(response => {
    return response.json();
  });
};

export const FetchBuildingScenarios = async ({ building, intl, signal, fromWorker = false }) => {
  return await fetch(`${fromWorker ? baseApiUrlWorker : baseApiUrl}/building/${building.id}/scenarioWithDetails`, {
    signal,
    method: 'GET',
  }).then(response => {
    return response.json();
  });
};
