import * as ErrorMessages from './ErrorMessages.js';

export const required = text => {
  if (text) {
    return null;
  } else {
    return ErrorMessages.isRequired;
  }
};

export const mustMatch = (field, fieldName) => {
  return (text, state) => {
    return state[field] == text ? null : ErrorMessages.mustMatch(fieldName);
  };
};

export const minLength = length => {
  return text => {
    return text?.length >= length ? null : ErrorMessages.minLength(length);
  };
};

export const maxLength = length => {
  return text => {
    if (text) {
      return text?.length <= length ? null : ErrorMessages.maxLength(length);
    }
    return null;
  };
};
export const isEmail = (value) => {
  return fieldName => {
    return /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(value)
      ? null
      : ErrorMessages.isEmail(fieldName);
  };
};

export const minValue = limit => {
  return value => {
    return value >= limit ? null : ErrorMessages.minValue(limit);
  };
};

export const maxValue = limit => {
  return value => {
    return value <= limit ? null : ErrorMessages.maxValue(limit);
  };
};

export const isNumeric = value => {
  if (!value || (!isNaN(Number(value)) && isFinite(Number(value)))) {
    return null;
  } else {
    return ErrorMessages.isNumeric;
  }
};
export const isText = value => {
  if (!value || (isNaN(value) && !isFinite(value))) {
    return null;
  } else {
    return ErrorMessages.isText;
  }
};

export const isDate = text => {
  let date = new Date(text);
  if (date instanceof Date && !isNaN(date.valueOf())) {
    return null;
  } else {
    return ErrorMessages.isDate;
  }
};

export const isEmpty = obj => {
  for (var key in obj) {
    if (obj.hasOwnProperty(key)) return false;
  }
  return true;
};
