import { MENU_CHANGE } from '../Actions/menu.js';
import createReducer from './general.js';

const initialState = '/';

const selectedMenu = (state, action) => {
  switch (action.type) {
    case MENU_CHANGE:
      return action.selectedMenu;
    default:
      return state;
  }
};

export const menuReducer = createReducer(initialState, {
  MENU_CHANGE: selectedMenu,
});
