import {
  faBuilding,
  faCity,
  faClipboardList,
  faFilePdf,
  faHome,
  faLightbulb,
  faList,
  faListAlt,
  faQuestionCircle,
  faSearch,
  faWrench,
} from '@fortawesome/free-solid-svg-icons';
import { lazy } from 'react';
import Home from '@/Components/HomePage/HomePage';
const RealEstates =  lazy(() => import( '@/Components/RealEstate/RealEstatesPage.js'));
const Diagnosis = lazy(() => import('@/Components/Diagnosis/Diagnosis'));
const Summary = lazy(() => import('@/Components/Summary/Summary'));
const ChangePassword = lazy(() => import('@/Components/Login/ChangePassword.js'));
const Building = lazy(() => import('@/Components/Building/components/BuildingDetails.js'));
const Actions = lazy(() => import('@/Components/Action/Actions.js'));
const Scenarios = lazy(() => import('@/Components/Scenario/Scenarios.js'));
const ChooseActions = lazy(() => import('@/Components/Action/ChooseActions.js'));
const Reports = lazy(() => import('@/Components/Report/Reports.js'));
const Contact = lazy(() => import('@/Components/Contact.js'));
const EnergyCheck = lazy(() => import('@/Components/EnergyCheck/EnergyCheckPage.js'));

const pages = {
  home: {
    needAuth: false,
    path: '/',
    titleId: 'page.home.title',
    icon: faHome,
    component: Home,
    exact: true,
  },
  realEstates: {
    needAuth: true,
    path: '/real_estates',
    titleId: 'page.real_estates.title',
    icon: faCity,
    component: RealEstates,
    exact: false,
  },
  changePassword: {
    needAuth: false,
    path: '/change_password/:token',
    titleId: 'page.change_password.title',
    icon: faHome,
    component: ChangePassword,
    exact: true,
  },
  building: {
    needAuth: true,
    path: '/building',
    titleId: 'page.building.title',
    icon: faBuilding,
    component: Building,
    exact: false,
  },
  energy_check: {
    needAuth: true,
    path: '/energy_check',
    titleId: 'page.energy_check.title',
    icon: faLightbulb,
    component: EnergyCheck,
    exact: false,
  },
  diagnosis: {
    needAuth: true,
    path: '/diagnosis',
    titleId: 'page.diagnosis.title',
    icon: faSearch,
    component: Diagnosis,
    exact: false,
  },
  summary: {
    needAuth: true,
    path: '/summary',
    titleId: 'page.summary.title',
    icon: faListAlt,
    component: Summary,
    exact: false,
  },
  actions: {
    needAuth: true,
    path: '/actions',
    titleId: 'page.actions.title',
    icon: faWrench,
    component: Actions,
    exact: false,
  },
  scenarios: {
    needAuth: true,
    path: '/scenarios',
    titleId: 'page.scenarios.title',
    icon: faClipboardList,
    component: Scenarios,
    exact: false,
  },
  chooseActions: {
    needAuth: true,
    path: '/scenario/actions',
    titleId: 'page.chooseActions.title',
    icon: faList,
    component: ChooseActions,
    exact: false,
  },
  reports: {
    needAuth: true,
    path: '/reports',
    titleId: 'page.reports.title',
    icon: faFilePdf,
    component: Reports,
    exact: false,
  },
  contact: {
    needAuth: false,
    path: '/contact',
    titleId: 'page.contact.title',
    icon: faQuestionCircle,
    component: Contact,
    exact: false,
  },
};

export default pages;
