import Snackbar from '@material-ui/core/Snackbar';
import React, { PureComponent } from 'react';
import CustomSnackbarContent from './SnackbarContent.js';

class CustomSnackbar extends PureComponent {
  constructor(props) {
    super(props);
    this.message = '';
    this.variant = '';
    this.optional = '';
    this.formattedMessageParameters = {};
    this.state = {
      isOpen: false,
    };
    this.closeSnackBar = this.closeSnackBar.bind(this);
    this.openSnackBar = this.openSnackBar.bind(this);
    this.handleClose = this.handleClose.bind(this);
  }
  closeSnackBar() {
    this.handleClose();
  }
  openSnackBar(message = '', variant = 'success', optional = '', formattedMessageParameters = {}) {
    this.message = message;
    this.formattedMessageParameters = formattedMessageParameters;
    this.variant = variant;
    this.optional = optional;
    this.setState({
      isOpen: true,
    });
  }

  handleClose(event, reason) {
    if (reason === 'clickaway') {
      return;
    }
    this.setState({
      isOpen: false,
    });
  }

  render() {
    const { isOpen } = this.state;

    return (
      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        open={isOpen}
        autoHideDuration={2500}
        onClose={this.handleClose}>
        <CustomSnackbarContent
          onClose={this.handleClose}
          variant={this.variant}
          message={this.message}
          formattedMessageParameters={this.formattedMessageParameters}
          optional={this.optional}
        />
      </Snackbar>
    );
  }
}

CustomSnackbar.displayName = 'CustomSnackbar';
CustomSnackbar.propTypes = {};
CustomSnackbar.defaultProps = {};

export default CustomSnackbar;
