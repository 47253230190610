import { useMediaQuery, useTheme } from '@material-ui/core';
import Avatar from '@material-ui/core/Avatar';
import Typography from '@material-ui/core/Typography';
import PropTypes from 'prop-types';
import React from 'react';
import Tooltip from '../Misc/CustomTooltip';

const Title = ({ classes, title }) => {
  const theme = useTheme();
  const isSmallScreenAndBelow = useMediaQuery(theme.breakpoints.down('xs'));
  return (
    <React.Fragment>
      {isSmallScreenAndBelow ? (
        <Tooltip
          title={
            title?.main + (title?.subtitle ? ' / ' + title?.subtitle : '')
          }>
          {title?.picture ? (
            <Avatar src={title?.picture} className={classes.avatar} />
          ) : (
            <Typography
              variant='h6'
              color='inherit'
              className={classes?.smallTitle}>
              {title?.main + (title?.subtitle ? ' / ' + title?.subtitle : '')}
            </Typography>
          )}
        </Tooltip>
      ) : (
        <React.Fragment>
          {title?.picture && (
            <Avatar src={title?.picture} className={classes.avatar} />
          )}
          <Typography variant='h6' color='inherit' className={classes?.title}>
            {title?.main + (title?.subtitle ? ' / ' + title?.subtitle : '')}
          </Typography>
        </React.Fragment>
      )}
    </React.Fragment>
  );
};

Title.displayName = 'Title';
Title.propTypes = {
  classes: PropTypes.object.isRequired,
  title: PropTypes.shape({
    main: PropTypes.string.isRequired,
    subtitle: PropTypes.string.isRequired,
    picture: PropTypes.string.isRequired,
  }).isRequired,
};
Title.defaultProp = {};

export default Title;
