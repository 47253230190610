import { ruleRunner, run } from './RuleRunner';
import { isEmail, isEmpty, maxLength, minLength } from './Rules';

const UserValidations = {
  username: ruleRunner(
    'username',
    'username',
    minLength(1),
    maxLength(255)
  ),
  email: ruleRunner('email', 'email', isEmail),
  password: ruleRunner(
    'password',
    'password',
    minLength(1),
    maxLength(255)
  ),
  confirm_password: ruleRunner(
    'confirm_password',
    'confirm_password',
    minLength(1),
    maxLength(255)
  ),
};
export const validateUserInput = (id, value, runner = null) => {
  runner = [UserValidations, runner].find(obj => Object.keys(obj).includes(id));
  let result = run(id, value, runner);
  Object.keys(result).forEach(k => {
    if(result[k] == null) delete result[k]
  });
  return result;
};
