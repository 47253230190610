import { resetData } from '@/Actions/';
import { baseApiUrl } from '@/Functions/';
import { faSignOutAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Grid, IconButton, Typography, withStyles } from '@material-ui/core';
import React from 'react';
import { injectIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import ToolTip from '../Misc/CustomTooltip';

const styles = (theme, props) => ({
  box: {
    height: '100%',
    margin: '0 15px',
    [theme.breakpoints.only('xs')]: {
      minHeight: '0',
      maxWidth: 'auto',
    },

    [theme.breakpoints.down('sm')]: {
      minHeight: '0',
      maxWidth: '18.75rem',
    },
  },
  userDetails: props => ({
    color: props?.color || theme.palette.primary.main,
    fontWeight: props?.fontWeight ?? 'normal',
    fontFamily: 'Avenir Next',
  }),
  menuButton: props => ({
    color: props?.color ?? theme.palette.primary.main,
    marginLeft: 12,
    marginRight: 12,
  }),
});

const SignedIn = ({
  classes,
  iconSize = 'lg',
  typographyComponent = 'h4',
  showSnackBar,
  ...props
}) => {
  const user = useSelector(state => state.user);
  const dispatch = useDispatch();
  const handleLogout = () => {
    if (user) {
      fetch(baseApiUrl+'/logout', {
        method: 'POST',
      })
        .then(response => response.json())
        .then(response => {
          if (response.success) {
            dispatch(resetData());
            showSnackBar(response.success, 'success');
          } else if (response.error) {
            showSnackBar(response.error, 'error');
          } else if (response.token_error) {
            showSnackBar('logout.success', 'success');
          } else {
            showSnackBar('app.response.unknown', 'error');
          }
        })
        .catch(error => {
          console.error(error);
        });
    }
  };

  return (
    user && (
      <Grid
        className={classes.box}
        container
        item
        xs={12}
        sm={4}
        wrap='nowrap'
        gap='15'
        justifyContent='center'
        alignItems='center'>
        <Typography
          variant={typographyComponent}
          className={classes.userDetails}>
          {user.username}
        </Typography>
        <ToolTip title='app.logout' formatted>
          <IconButton className={classes.menuButton} onClick={handleLogout}>
            <FontAwesomeIcon icon={faSignOutAlt} size={iconSize} />
          </IconButton>
        </ToolTip>
      </Grid>
    )
  );
};

export default injectIntl(withStyles(styles)(SignedIn));
