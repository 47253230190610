import { CircularProgress } from '@material-ui/core';
import PropTypes from 'prop-types';
import React, { Suspense } from 'react';
import { connect } from 'react-redux';
import { Redirect, Route, Switch } from 'react-router-dom';
import pages from '../Structure/Pages.js';
import ErrorBoundary from './Misc/ErrorBoundary.js';
import PrivateRoute from './Misc/PrivateRoute.js';

const Page = ({ classes, user }) => (
  <ErrorBoundary>
    <Suspense
      fallback={
        <div className={classes.centerFlexWH100}>
          <CircularProgress size='10rem' />
        </div>
      }>
      <Switch>
        {Object.keys(pages).map((key, index) =>
          pages[key].needAuth ? (
            <PrivateRoute
              key={pages[key].titleId}
              exact={pages[key].exact}
              path={pages[key].path}
              component={pages[key].component}
              user={user}
            />
          ) : (
            <Route
              key={pages[key].titleId}
              exact={pages[key].exact}
              path={pages[key].path}
              component={pages[key].component}
            />
          )
        )}
        <Redirect to='/' />
      </Switch>
    </Suspense>
  </ErrorBoundary>
);

Page.displayName = 'Page';
Page.propTypes = {
  classes: PropTypes.object.isRequired,
  user: PropTypes.object,
};
Page.defaultProps = {
  user: null,
};

const mapStateToProps = state => ({
  user: state.user,
});

export default connect(mapStateToProps)(Page);
