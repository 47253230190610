import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import Drawer from '@material-ui/core/Drawer';
import PageMenu from '../Menu/PageMenu.js';

import theme from '../../Themes/main.js';

const LeftMenu = ({ classes }) => (
  <Drawer
    anchor="left"
    variant="permanent"
    className={classes.drawerClose}
    classes={{paper: classes.drawerClose}}
  >
    <div className={classes.toolbar}/>
    <PageMenu />
  </Drawer>
);

LeftMenu.displayName = "LeftMenu";
LeftMenu.propTypes = {
  classes: PropTypes.object.isRequired,
};
LeftMenu.defaultProps = {};

export default LeftMenu;
