export const IMPORT_REAL_ESTATES_LIST = "IMPORT_REAL_ESTATES_LIST";
export const INSERT_REAL_ESTATE = "INSERT_REAL_ESTATE";
export const DELETE_REAL_ESTATE = "DELETE_REAL_ESTATE";

export const UPDATE_REAL_ESTATE = "UPDATE_REAL_ESTATE";
export const SELECTION_REAL_ESTATE_CHANGE = "SELECTION_REAL_ESTATE_CHANGE";
export const INSERT_BUILDING = "INSERT_BUILDING";
export const UPDATE_BUILDING = "UPDATE_BUILDING";
export const DELETE_BUILDINGS = "DELETE_BUILDINGS";

export const ImportRealEstateList = realEstates => ({
  type: IMPORT_REAL_ESTATES_LIST,
  payload: { realEstates: [...realEstates] },
});

export const InsertRealEstate = realEstate => ({
  type: INSERT_REAL_ESTATE,
  payload: { realEstate: { ...realEstate } },
});
export const DeleteRealEstate = realEstateIndex => ({
  type: DELETE_REAL_ESTATE,
  payload: { id: realEstateIndex },
});

export const UpdateRealEstate = realEstate => ({
  type: UPDATE_REAL_ESTATE,
  payload: { realEstate },
});
export const SelectionRealEstateChange = id => ({
  type: SELECTION_REAL_ESTATE_CHANGE,
  payload: { id },
});

// REAL ESTATE -> BUILDINGS FUNCTIONS.
export const InsertBuilding = building => ({
  type: INSERT_BUILDING,
  payload: { building: { ...building } },
});
export const UpdateBuilding = building => ({
  type: UPDATE_BUILDING,
  payload: { building: { ...building } },
});

export const DeleteBuildings = deleteList => ({
  type: DELETE_BUILDINGS,
  payload: { deleteBuildingsList: [ ...deleteList] },
});
