const styles = theme => ({
  container: {
    gap: '2.5rem',
    height: '100%',
    paddingTop: '2rem',
    overflow: 'scroll',
    backgroundColor: theme.palette.appBackgroundColor,
    '& > *': {
      flexBasis: 0,
    },
  },
  logo: {
    color: 'rgba(0,0,0,0.55)',
    fontFamily: 'Avenir Next',
    textAlign: 'center',
    lineHeight: '2.8rem',
  },
  fontLight: {
    fontWeight: 'light',
  },
  fontBold: {
    fontWeight: 'bold',
    '&:nth-child(2)': {
      margin: ' 0 1rem',
    },
  },
  bottomContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: '20px',
    justifyContent: 'center',
    alignItems: 'center',
  },
  loginText: {
    fontFamily: 'Avenir Next',
    textAlign: 'center',
    fontWeight: 'bold',
  },
  popupBg: {
    backgroundColor: theme.palette.appBackgroundColor,
  },
  greenColor: {
    color: theme.palette.primary.dark,
  },
});

export default styles;
